import { useEffect, useState } from "react";
import {Link} from 'react-router-dom';

const HospitalProfile = () => {
     
    const [clinicProfile, updateClinicProfile] = useState({});

    const getClinicProfile = () =>{
        var input = {
            "hospitalid": localStorage.getItem("hospitalid"),
            "id": localStorage.getItem("id")
        };
        const requestOptions = {
            method : 'POST',
            headers: { 'Content-Type':'application/json'},
            body: JSON.stringify(input)
        };
        fetch("https://www.medicalplanet.in/webapi/Hospital/details", requestOptions)
        .then(response=>response.json())
        .then(data=>{
            // console.log(data);
            updateClinicProfile(data);
        })
    }

    useEffect(()=>{
        getClinicProfile();
    }, [])
  
    return(
       <div className="container mt-3">
        <div className="row">
            <div className="col-lg-12">
                <h4 className="text-center">Clinic Name: <span className="text-info">{clinicProfile.name}</span></h4>
            </div>
        </div>
        <div className="row mt-3">
            <div className="col-lg-4 offset-4">
                <table className="table" colSpan={5}>
                    <tbody>                                                                                        
                        <tr>
                            <th className="text-center">Clinic ID</th>    
                            <td className="text-start">{clinicProfile.hospitalid}</td>
                        </tr>  
                        <tr>
                            <th className="text-center">Clinic Name</th>    
                            <td className="text-start">{clinicProfile.name}</td>
                        </tr>                                        
                        <tr>
                            <th className="text-center">Mobile</th>    
                            <td className="text-start">{clinicProfile.mobile}</td>
                        </tr>                                        
                        <tr>
                            <th className="text-center">Email</th>    
                            <td className="text-start">{clinicProfile.email}</td>
                        </tr>                                        
                        <tr>
                            <th className="text-center">Address</th>    
                            <td className="text-start">{clinicProfile.address}</td>
                        </tr>                                        
                        <tr>
                            <th className="text-center">City</th>    
                            <td className="text-start">{clinicProfile.city}</td>
                        </tr>                                        
                        <tr>
                            <th className="text-center">State</th>    
                            <td className="text-start">{clinicProfile.state}</td>
                        </tr>                                        
                        <tr>
                            <th className="text-center">Pincode</th>    
                            <td className="text-start">{clinicProfile.pincode}</td>
                        </tr>                                        
                        <tr>
                            <th className="text-center">Active</th>    
                            <td className="text-start">{clinicProfile.active}</td>
                        </tr>                                                                                                        
                    </tbody>
                </table>
                <div className="text-center">
                    <Link to="/hospitaleditprofile"><button className="btn btn-primary"><i className="fa fa-edit"></i> Edit</button></Link>
                </div>
            </div>
        </div>
       </div>
    );
}

export default HospitalProfile;