import LoginApp from './components/login/loginApp.js';
import HospitalApp from './components/Hospital/hospitalApp.js';
import StaffApp from './components/Staff/staffApp.js';
import DoctorApp from './components/Doctor/doctorApp.js';
import ClinicApp from './components/Clinic/clinicApp.js';

function App() {
  // localStorage.clear();
  if(localStorage.getItem("id") == null){
    return(
      <LoginApp />
    )    
  }else{
    if(localStorage.getItem("type") == "HOSPITAL"){
      return(
        <HospitalApp />
      )
    }
  }
  if(localStorage.getItem("type") == "STAFF"){
    return(
      <StaffApp />
    )
  }
  if(localStorage.getItem("type") == "DOCTOR"){
    return(
      <DoctorApp />
    )
  }
  if(localStorage.getItem("type") == "CLINIC"){
    return(
      <ClinicApp />
    )
  }
}

export default App;
