import React from "react";
import {Link} from 'react-router-dom';

const HospitalHeader = () =>{
    return(  
        <>
        <nav className="navbar navbar-expand-md navbar-dark fixed-top navbarbg mb-5">
            <div className="container-fluid">
                <Link className="navbar-brand text-warning" to="/"><i className="fa fa-heartbeat"></i> Medical Planet</Link>
                <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarCollapse" aria-controls="navbarCollapse" aria-expanded="false" aria-label="Toggle navigation">
                <span className="navbar-toggler-icon"></span>
                </button>
                <div className="collapse navbar-collapse" id="navbarCollapse">        
                    <ul className="navbar-nav mx-auto mb-2 mb-md-0">
                        <li className="nav-item">
                        <Link className="nav-link active" to="/"><i className="fa fa-dashboard"></i> Dashboard</Link>
                        </li>

                        <li className="nav-item">
                        <Link className="nav-link active" to="/hospitaldoctor"><i className="fa fa-user-md"></i> Doctor</Link>
                        </li>

                        <li className="nav-item">
                            <Link className="nav-link active" to="/hospitalstaff"><i className="fa fa-stethoscope"></i> Staff</Link>
                        </li>

                        <li className="nav-item">
                            <Link className="nav-link active" to="/hospitallabs"><i className="fa fa-flask"></i> Labs</Link>
                        </li>

                        <li className="nav-item">
                            <Link className="nav-link active" to="/hospitalpharma"><i className="fa fa-medkit"></i> Pharma</Link>
                        </li>

                        <li className="nav-item">
                            <Link className="nav-link active" to="/hospitalpatients"><i className="fa fa-users"></i> Patients</Link>
                        </li>

                        <li className="nav-item">
                            <Link className="nav-link active" to="/hospitalbilling"><i className="fa fa-inr"></i> Billing</Link>
                        </li>

                        <li className="nav-item">                       
                            <Link className="nav-link menu active text-warning" to="/"><b><i>{localStorage.getItem("name")}</i></b>  <i className="fa fa-power-off text-danger" onClick={logout}> Logout </i>
                            </Link>
                        </li>                        
                    </ul>
                </div>
            </div>
        </nav>
        <br/> <br />
        </>         
    );
}

export default HospitalHeader;

const logout = () =>{
    localStorage.clear();
    window.location.reload();
}