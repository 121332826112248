import React, {useState, useEffect} from "react";
import { Link } from "react-router-dom";

const Appointments = () =>{
    const [paymentlist, updatePaymentList] = useState([]);
    const [pDetails, updatepDetails] = useState("");

    const getPaymentList = () =>{
        let input= {
            "hospitalid": localStorage.getItem("hospitalid"),
            "id": localStorage.getItem("id")           
        };
        const requestOptions = {
            method: 'POST',
            headers: {'Content-Type' : 'application/json'},
            body: JSON.stringify(input)
        };
        fetch("https://www.medicalplanet.in/webapi/Doctor/getpatients", requestOptions)
        .then(response => response.json())
        .then(data =>{
            // console.log(data);
            updatePaymentList(data);
        })
    }

    const searchPatient = () =>{
        let input = {
            "input": pDetails,
            "id": localStorage.getItem("id"),
            "hospitalid":localStorage.getItem("hospitalid")
        };
        const requestOptions = {
            method: 'POST',
            header: {'Content-Type' : 'application/json' },
            body : JSON.stringify(input)
        };
        fetch("https://www.medicalplanet.in/webapi/Doctor/searchpatients", requestOptions)
        .then(response => response.json())
        .then(data =>{
            // console.log(data);
            updatePaymentList(data);
        })
    }

    const clearPatient = () =>{
        updatepDetails("");
        getPaymentList();
    }

    useEffect(()=>{
        getPaymentList();
    },[1]);

    return(
        <div className="container">
           <div className="row mt-4 mb-2">
               <div className="col-lg-3">
                   <h4 className="text-end">Todays Appointments:</h4>
               </div>
               <div className="col-lg-2 text-start">
                   <h4 className="">{paymentlist.length}</h4>
               </div>
               <div className="col-lg-3"></div>                             
               <div className="col-lg-4">
                    <div className="input-group">
                        <input type="text" className="form-control p-1 " onChange={obj=>updatepDetails(obj.target.value)} value={pDetails}/>
                        <button className="btn btn-success btn-sm p-2 " onClick={searchPatient}><i className="fa fa-search"></i> Search </button>
                        <button className="btn btn-warning btn-sm p-2" onClick={clearPatient}><i className="fa fa-remove"></i> Clear</button>
                    </div>
               </div>
           </div>
           <div className="row mt-4">
               <div className="col-lg-12">
                   <table className="table table-sm shadow rounded">
                       <thead>
                           <tr className="bg-light text-primary">
                               <th>PID</th>
                               <th>Patient Details</th>
                               <th>Doctor Name</th>
                               <th>Fees</th>
                               <th>Consulted</th>
                               <th>Fee Paid</th>
                               <th>Last Visit</th>
                               <th>Payment</th>                               
                           </tr>
                       </thead>
                       <tbody>
                           {
                               paymentlist.map((data, index)=>{
                                   return(
                                       <tr key={index}>
                                           <td>{data.pid}</td>
                                           <td>
                                               {data.name} - 
                                               {data.mobile} - 
                                               {data.email}
                                            </td>
                                            <td>{data.doctorname}</td>
                                            <td>{data.fee}</td>
                                            <td>{data.flag}</td>
                                            <td>{data.feepaid}</td>
                                            <td>{data.lastvisit}</td>
                                            <td>
                                                <Link to={`/editappointment/${data.pid}`}><button className="btn btn-info btn-sm m-1" data-toggle="tooltip" data-placement="bottom" title="Pay"><i className="fa fa-rupee"></i> Pay </button></Link>
                                                <button className="btn btn-warning btn-sm" data-toggle="tooltip" data-placement="bottom" title="Print"><i className="fa fa-print"></i> </button>
                                            </td>
                                       </tr>
                                   )
                               })
                           }
                       </tbody>
                   </table>
               </div>
           </div>
       </div>
    );
}

export default Appointments;