import React, { useEffect, useState } from "react";

const DocDeleteSlots = () =>{
    const [getSlots, updateGetSlots] = useState([]);
    const [message, updateMessage] = useState("");
    const [searchDate, updateSearchDate] = useState("");

    const getAllSlots = () =>{
        let input={ "doctorid":localStorage.getItem("id")};
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(input)
        };
        fetch('https://www.medicalplanet.in/webapi/Doctor/getmyslots', requestOptions)
        .then(response => response.json())
        .then(data =>{
            if(data.length > 0){
                updateGetSlots(data);
            }else{
                updateMessage("Slots not available");
            }
            
        });
    }

    const deleteSlot = (slotid) =>{
        alert("Are you sure to delete this slot?");
        let input={ 
			"doctorid":localStorage.getItem("id"),
			"slotid": slotid
		  };
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(input)
        };
        fetch('https://www.medicalplanet.in/webapi/Doctor/cancelslot', requestOptions)
        .then(response => response.text())
        .then(data =>{
            updateMessage(data);
            window.location.reload();
        });
    }

    useEffect(()=>{
        getAllSlots();
    }, [1]);

    return(
        <div className="container">
            <div className="row mt-3">
                <div className="col-lg-4">
                    <div className="input-group">
                        <span className="input-group-text">
                            <i>Search By Data</i>
                        </span>
                        <input type="date" className="form-control" onChange={obj=>updateSearchDate(obj.target.value)} />
                    </div>
                </div>
                <div className="col-lg-8">
                    <h3 className="text-info">My Appointments : {getSlots.length}</h3>
                    <p className="text-center text-danger">{message}</p>
                </div>
            </div>
            <div className="row mt-4">
                {
                        getSlots.filter(({datemonth})=>{
                            return datemonth.indexOf(searchDate) >= 0; 
                        }).map((dslots, index)=>{
                            return(
                                <div key={index} className="col-lg-3 mb-4">
                                    <div className="p-3 purple shadow rounded text-white">
                                        <b><u>{dslots.slot}</u> - {dslots.datemonth}</b>
                                        <div>{dslots.name}</div>
                                        <div>{dslots.mobile}</div>
                                        <div>Last Visit :{dslots.lastvisit}</div>
                                    </div>
                                </div>
                            )
                        })
                }
            </div>
        </div>
    );
}

export default DocDeleteSlots;