import React, { useEffect, useState } from "react";
import { ToastContainer, toast } from "react-toastify";

const NewPatient = () => {
    const [alldoc, updateAllDoc] = useState([]);
    const [pfee, updatepFee] = useState("");
    const [pname, updatepName] = useState("");
    const [pmobile, updatepMobile] = useState("");
    const [pemail, updatepEmail] = useState("");
    const [pdocid, updatepDocID] = useState("");
    const [pdoctor, updatepDoctor] = useState("");
    const [message, updateMessage] = useState("");
    const [paddress, updatepAddress] = useState("");

    const getAllDoctors = () => {
        let input = {
            "id": localStorage.getItem("id"),
            "hospitalid": localStorage.getItem("hospitalid")
        };
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(input)
        };
        fetch('https://www.medicalplanet.in/webapi/Hospital/doctorforhospital', requestOptions)
            .then(response => response.json())
            .then(data => {
                // console.log(data);
                if (data.length > 0) {
                    updateAllDoc(data);
                }
            });
    }
    const getAmount = (did) => {
        alldoc.map((ddata, index) => {
            if (did === ddata.doctorid) {
                updatepFee(ddata.amount);
                updatepDocID(ddata.doctorid);
                updatepDoctor(ddata.name);
            }
        })
    }

    const savePatient = () => {
        var emailRegEx = /^([a-zA-Z0-9_\-\.]+)@([a-zA-Z0-9_\-\.]+)\.([a-zA-Z]{2,5})$/;
        var mobileRegEx = /^[6-9]\d{9}$/;
        var nameRegEx = /[a-zA-Z]$/;

        if (pname === "" || pemail === "" || pmobile === "" || paddress === "") {
            toast.error("Enter all values");
        } else if (!nameRegEx.test(pname)) {
            toast.error("Name cannot contain numbers and special characters");
        } else if (!emailRegEx.test(pemail)) {
            toast.error("Enter Email in proper format");
        } else if (!mobileRegEx.test(pmobile)) {
            toast.error("Enter Mobile number in proper format");
        }
        else {
            let input = {
                "name": pname,
                "email": pemail,
                "mobile": pmobile,
                "address": paddress,
                "doctorid": pdocid,
                "fee": pfee,
                "hospitalid": localStorage.getItem("hospitalid")
            };
            const requestOptions = {
                method: 'POST',
                header: { 'Content-Type': 'application/json' },
                body: JSON.stringify(input)
            };
            fetch("https://www.medicalplanet.in/webapi/Doctor/newpateint", requestOptions)
                .then(response => response.text())
                .then(data => {
                    updateMessage(data);
                    updatepName(""); updatepMobile(""); updatepEmail(""); updatepAddress("");
                    updatepDocID("");
                    //    window.location.reload();
                })
        }
    }

    const clearPatient = () => {
        updatepName(""); updatepMobile(""); updatepEmail(""); updatepAddress("");
        updatepDocID("");
        updatepDocID("");
        window.location.reload();
    }

    useEffect(() => {
        getAllDoctors();
    }, [1]);

    return (
        <div className="container">
            <div className="row mt-4">
            <div className="col-lg-2"></div>
                <div className="col-lg-8">
                    <h3 className="text-primary text-center"> 
                        <i className="fa fa-user-plus"></i> Register New Patient 
                    </h3>
                <p className="text-center text-danger">{message}</p>
                    <div className="card border-0 shadow">
                        <div className="card-header bg-info text-white">
                            <b><i className="fa fa-edit"></i> Enter Patient Information</b>
                        </div>
                        <div className="card-body">
                        
                            <div className="row">
                                <div className="col-lg-6">
                                    <div className="mt-1">
                                        <label>Name</label>
                                        <input type="text" className="form-control" onChange={obj => updatepName(obj.target.value)} value={pname} />
                                    </div>
                                    <div className="mt-1">
                                        <label>Email</label>
                                        <input type="text" className="form-control" onChange={obj => updatepEmail(obj.target.value)} value={pemail} />
                                    </div>
                                    <div className="mt-1">
                                        <label>Address</label>
                                        <textarea className="form-control" rows="3" onChange={obj => updatepAddress(obj.target.value)} value={paddress}></textarea>
                                    </div>
                                </div>
                                <div className="col-lg-6">
                                    <div className="mt-1">
                                        <label>Mobile</label>
                                        <input type="text" className="form-control" onChange={obj => updatepMobile(obj.target.value)} value={pmobile} />
                                    </div>
                                    <div className="mt-1">
                                        <label>Doctor</label>
                                        <select className="form-select" onChange={obj => getAmount(obj.target.value)} >
                                            <option>Choose</option>
                                            {
                                                alldoc.map((ddata, index) => {
                                                    return (
                                                        <option key={index} value={ddata.doctorid}>{ddata.name}</option>
                                                    )
                                                })
                                            }
                                        </select>
                                    </div>
                                    <div className="mt-1">
                                        <label>Fees</label>
                                        <input type="text" className="form-control" onChange={obj => updatepFee(obj.target.value)} value={pfee} />
                                    </div>

                                </div>
                            </div>
                        </div>
                        <div className="card-footer d-flex justify-content-center">
                        <button className="btn btn-primary rounded m-2" onClick={savePatient}>Save Patient Details</button>
                    <button className="btn btn-danger rounded m-2" onClick={clearPatient}>Clear All</button>
                        </div>
                    </div>
                </div>
            </div>
           
            <ToastContainer position="top-right"
                autoClose={2000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover />
        </div>
    );
}

export default NewPatient;