import React, { useEffect, useState } from "react";


const DocBookings = () =>{
    const [appointments, updateAppointments] = useState([]);
    const [userone, updateOne] = useState({});
    

    // const [style, setStyle] = useState("");

    const [pname, updatepName] = useState("");
    const [pmobile, updatepMobile] = useState("");
    const [pemail, updatepEmail] = useState("");
    const [pdiagnosis, updatepDiagnosis] = useState("");
    const [pmedicine, updatepMedicine] = useState("");
    const [pdetails, updatepDetails] = useState("");
    const [psymptoms, updatepSymptons] = useState("");
    const [pattended, updatepAttended] = useState("");
    const [message, updateMessage] = useState("");
    const [pid, updatepid] = useState("");
    const [pfee, updatepfee] = useState("");


    const editPatient = (index) =>{        
        updateOne(appointments[index]);
        updatepName(appointments[index].name);
        updatepMobile(appointments[index].mobile);
        updatepEmail(appointments[index].email);
        updatepid(appointments[index].pid);
        updatepfee(appointments[index].fee);
    }

    const getBookingDetails = () =>{
        let input = { "doctorid": localStorage.getItem("id"),
            "hospitalid": localStorage.getItem("hospitalid"),
        };
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(input)
        };
        fetch('https://www.medicalplanet.in/webapi/Doctor/getbooking', requestOptions)
            .then(response => response.json())
            .then(data => {                
                if(data.length>0){   
                    console.log(data);
                    updateAppointments(data);                  
                    updateOne(data[0]);  
                    updatepid(data[0].pid);
                    updatepName(data[0].name);
                    updatepMobile(data[0].mobile);
                    updatepEmail(data[0].email);
                    updatepfee(data[0].fee);
                }               
            });
    }

    const savePDetails = () =>{
        let input = {
            "appointmentid": userone.aid,
            "name": pname,
            "mobile": pmobile,
            "email": pemail,
            "diagnosis": pdiagnosis,
            "medicine": pmedicine, 
            "details": pdetails,
            "symptoms": psymptoms,
            "pid": pid,
            "fee": pfee,
            "id":localStorage.getItem("id"),
            "hospitalid": localStorage.getItem("hospitalid"),
            "doctorid": localStorage.getItem("id")
        };
        const requestOptions = {
            method: 'POST',
            headers: {'Content-Type':'application/json'},
            body: JSON.stringify(input)
        }
        fetch("https://www.medicalplanet.in/webapi/doctor/savepatient", requestOptions)
        .then(response => response.text())
        .then(data =>{
            // console.log(data);
            updateMessage(data);
            window.location.reload();
        })
    }

    useEffect(()=>{
        getBookingDetails(); 
    },[1]);

    return(       
        <div className="container mt-4">            
            <div className=" row mt-2">                                
                <p className="text-center text-danger">{message}</p>
                <div className="col-lg-3">
                        <h4 className="text-center text-info">Waiting Patient : {appointments.length}</h4>                                            
                        <div className="p-2 scroll">                                                                              
                                {
                                    appointments.map((pdata, index)=>{ 
                                        return(                                                 
                                            <div key={index} onClick={editPatient.bind(this, index)} className="purple rounded p-3 shadow mb-3">                           
                                                <p className="text-white m-0">{index + 1}  : {pdata.name} - {pdata.slot}</p>                                          
                                            </div>                                      
                                        )                                         
                                    })                                   
                                }                                                           
                        </div>                
                </div>
                <div className="col-lg-9">
                    <div className="p-3 shadow rounded">
                                <div className="row mt-3">
                                    <div className="col-lg-7">
                                        <h4 className="mt-2 text-center text-info"> {userone.name} Details</h4>    
                                    </div>                                   
                                    
                                    <div className="col-lg-5 text-end">                                       
                                        <a className="#"> View {userone.name} - History </a>
                                    </div>      
                                    <div className="col=lg-1"></div>                              
                                </div>                
                   <div className="row">
                       <div className="col-lg-1"></div>
                       <div className="col-lg-5">                           
                       <div className="mt-2">
                                <label>Symptoms</label>
                                <textarea className="form-control" tabindex="1"
                                onChange={obj=>updatepSymptons(obj.target.value)} value={psymptoms}></textarea>
                            </div> 
                            <div className="mt-3">
                                <label>Diagnosis</label>
                                <textarea className="form-control" tabindex="2"
                                onChange={obj=>updatepDiagnosis(obj.target.value)} value={pdiagnosis}></textarea>
                            </div>
                            <div className="mt-3">
                                    <label>Name</label>
                                    <input type="text" className="form-control" onChange={obj=>updatepName(obj.target.value)} value={pname} />
                                </div>   
                                <div className="mt-3">
                                    <label>Email</label>
                                    <input type="text" className="form-control" onChange={obj=>updatepEmail(obj.target.value)} value={pemail} />
                                </div>  
                       </div>
                       <div className="col-lg-5">
                           
                            <div className="mt-2">
                                <label>Medicines</label>
                                <textarea className="form-control" tabindex="3" 
                                onChange={obj=>updatepMedicine(obj.target.value)} value={pmedicine}></textarea>
                            </div> 
                            <div className="mt-3">
                                <label>Advice</label>
                                <textarea className="form-control" tabindex="4" 
                                onChange={obj=>updatepDetails(obj.target.value)} value={pdetails}></textarea>
                            </div>  
                            <div className="mt-3">
                                    <label>Mobile</label>
                                    <input type="text" className="form-control" onChange={obj=>updatepMobile(obj.target.value)} value={pmobile} />
                                </div>                                                                                                  
                                <div className="mt-3">
                                    <label>Attended</label>
                                    <select className="form-select" value={pattended} onChange={obj=>updatepAttended(obj.target.value)}>                            
                                        <option>YES</option>
                                        <option>NO</option>
                                    </select>
                                </div>            
                        </div>
                       <div className="col-lg-1"></div>
                   </div>
                   <div className="row">
                       <div className="col-lg-4 offset-4">
                            <div className="mt-4 text-center">
                                <button className="btn btn-primary rounded" onClick={savePDetails}>
                                    <i className="fa fa-check"></i> Complate Consultation
                                </button>
                            </div>                            
                       </div>                       
                   </div>
                </div>
                </div>
            </div>
        </div>
    );
}

export default DocBookings;


{/* <DatePicker
                                                className="form-control float-end"
                                                placeholder="Date Picker"
                                                selected={Dtodate}
                                                onChange={date => updateDtodate(date)}
                                                dateFormat='yyyy-MM-dd'
                                            />     */}