import React from "react";
import {Link} from 'react-router-dom';

const StaffHeader = () =>{
    return(
      <>        
        <nav className="navbar navbar-expand-md navbar-dark fixed-top navbarbg">
            <div className="container-fluid">
                <Link className="navbar-brand text-warning" to="/appointments"><i className="fa fa-heartbeat "></i> Medical Planet</Link>
                <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarCollapse" aria-controls="navbarCollapse" aria-expanded="false" aria-label="Toggle navigation">
                <span className="navbar-toggler-icon"></span>
                </button>
                <div className="collapse navbar-collapse" id="navbarCollapse">        
                    <ul className="navbar-nav ms-auto mb-2 mb-md-0">
                        <li className="nav-item">
                        <Link className="nav-link active" aria-current="page" to="/appointments"><i className="fa fa-calendar"></i> Appointments</Link>
                        </li>
                        <li className="nav-item">
                        <Link className="nav-link active" aria-current="page" to="/newpatient"><i className="fa fa-plus-square"></i> New Patient</Link>
                        </li>                      
                        
                        <li className="nav-item">                       
                            <a className="nav-link menu text-white"> Welcome - {localStorage.getItem("name")} <i className="fa fa-power-off text-danger" onClick={logout}> Logout </i>
                            </a>
                        </li>
                    </ul>
                </div>
            </div>
        </nav>
<br/> <br/> <br />
    </>

    );
}

export default StaffHeader;

const logout = () =>{
    localStorage.clear();
    window.location.reload();
}