import React, {useState, useEffect} from "react";
import {Link} from "react-router-dom";
const PharmaBill = () =>{
    const [pharmaBilling, updatePharmaBilling] = useState([]);
    const printBill = () =>{
        var input = {
         "hospitalid": localStorage.getItem("hospitalid"),
         "id": localStorage.getItem("id")
        };
        const requestOptions = {
         method : 'POST',
         headers : {'Content-Type' : 'application/json'},
         body : JSON.stringify(input)
     };
     fetch("https://www.medicalplanet.in/webapi/Cart/pharmabill", requestOptions)
     .then(response => response.json())
     .then( data =>{
        //  console.log(data); 
         updatePharmaBilling(data);
     })
     }

    useEffect(()=>{
        printBill();
    },[])

    return(
        <div className="container mt-4">
            <div className="row">
                    <div className="col-lg-12 text-end">
                        <div className="btn-group">
                            <Link className="btn btn-success" to="/hospitalbilling/consulation">
                            <i className="fa fa-user-md"></i> Consultation Billing
                            </Link>
                            <Link className="btn btn-primary"  to="/hospitalbilling/pharma">
                            <i className="fa fa-medkit"></i> Pharma Billing
                            </Link>
                        </div>
                    </div>
                </div>
            <div className="row mt-4">
                <div className="col-lg-12">
                    <h4 className="text-center text-primary">Today's Pharma Billing</h4>
                    <table className="table table-bordered mt-4 shadow rounded">
                        <thead>
                            <tr className="bg-light text-primary">
                                <th>PID</th>
                                <th>Patient Name</th>
                                <th>Mobile</th>
                                <th>Date</th>
                                <th>Total</th>
                                <th>Download</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                pharmaBilling.map((pdata, index)=>{
                                    return(
                                        <tr key={index}>
                                            <td>{pdata.pid}</td>
                                            <td>{pdata.name}</td>
                                            <td>{pdata.mobile}</td>
                                            <td>{pdata.date}</td>
                                            <td>{pdata.total}</td>
                                            <td className="text-center">
                                                <a href={`https://www.medicalplanet.in/webapi/Cart/getpharmabill/${pdata.orderid}`} target="_blank"><i className="fa fa-download"></i> Download</a>
                                            </td>
                                        </tr>
                                    )
                                })
                            }
                        </tbody>
                    </table>
                </div>

            </div>
        </div>
    );
}

export default PharmaBill;