import React from "react";
import StaffHeader from "./staffheader";
import Appointments from "./appoint.js";
import EditAppointment from "./editAppointment";
import NewPatient from "./newpatient";
import { HashRouter, Routes, Route } from "react-router-dom";

const StaffApp = () =>{
    return(
       <HashRouter>
           <StaffHeader />         
           <Routes>
               <Route exact path="/appointments" element={<Appointments />} />              
               <Route exact path="/editappointment/:pid" element={<EditAppointment />} />
               <Route exact path="/newpatient" element={<NewPatient />} />
           </Routes>
       </HashRouter>
    );
}

export default StaffApp;