import React, { useEffect, useState } from "react";
import {Link} from 'react-router-dom';

const HospitalPatients = () =>{
    const [searchPatient, updateSearchPatient] = useState("");
    const [patientList, updatePatientList] = useState([]);
    const [message, updateMessage] = useState("");

    const getPatientList = () =>{
        let input= {
            "hospitalid":localStorage.getItem("hospitalid"),
            "id":localStorage.getItem("id")           
        };
        const requestOptions = {
            method: 'POST',
            headers: {'Content-Type' : 'application/json'},
            body: JSON.stringify(input)
        };
        fetch("https://www.medicalplanet.in/webapi/Hospital/patientforhospital", requestOptions)
        .then(response => response.json())
        .then(data =>{            
            if(data.length>0){
                // console.log(data);
                updatePatientList(data);
            }
        });
    }

    const patientSearch = () =>{
        let input = {
            "input": searchPatient,
            "hospitalid":localStorage.getItem("hospitalid"),
            "id":localStorage.getItem("id")
        };
        const requestOptions = {
            method: 'POST',
            header: {'Content-Type' : 'application/json' },
            body : JSON.stringify(input)
        };
        fetch("https://www.medicalplanet.in/webapi/Doctor/searchpatients", requestOptions)
        .then(response => response.json())
        .then(data =>{            
            if(data.length>0){
                updatePatientList(data);
            }else{
                updatePatientList([]); 
                updateMessage("Patient record not found");
            }
        })
    }

    const clearSearch = () =>{        
        updateSearchPatient("");
        getPatientList();
        updateMessage("");
    }

    useEffect(()=>{
        getPatientList();
    },[1]);

    return(
        <div className="container mt-4">
            <div className="row">
                <div className="col-lg-4 text-start">
                    <h4>Todays Appointments: {patientList.length}</h4>
                </div>
                <div className="col-lg-3 "> </div>
                <div className="col-lg-5 text-end">
                    <div className="input-group">
                        <input type="search" className="form-control" placeholder="Mobile No or PID" onChange={obj=>updateSearchPatient(obj.target.value)} value={searchPatient} />
                        <div className="input-group-append">
                            <button className="btn btn-success text-white" onClick={patientSearch}><i className="fa fa-search" ></i> Search</button>
                        </div>
                        <div className="input-group-append">
                            <button className="btn btn-warning text-dark" onClick={clearSearch}><i className="fa fa-remove"></i> Clear</button>
                        </div>
                    </div>
                </div>
            </div>
            <div className="row mt-4">
                <p className="text-center text-danger">{message}</p>
                <table className="table table-bordered shadow table-sm">
                    <thead className="text-primary bg-light">
                        <tr>
                            <th>Sl.No</th>
                            <th>ID</th>
                            <th>Patient Name</th>
                            <th>Doctor Name</th>
                            <th>Contact Details</th>
                            <th>Fees</th>
                            <th>Fee Paid</th>
                            <th>Last Visit</th>
                            <th>Pay</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            patientList.map((pdata, index)=>{
                                return(
                                    <tr key={index}>
                                        <td>{index + 1}</td>
                                        <td>{pdata.pid}</td>
                                        <td>{pdata.name}</td>
                                        <td>{pdata.doctorname}</td>
                                        <td>
                                            <p>{pdata.mobile}</p>
                                            <p>{pdata.email}</p>
                                        </td>
                                        <td>{pdata.fee}</td>
                                        <td>{pdata.feepaid}</td>
                                        <td>{pdata.lastvisit}</td>
                                        <td>
                                        <Link to={`/editappointment/${pdata.pid}`}><button className="btn btn-info">Pay</button></Link>
                                        </td>
                                    </tr>
                                )
                            })
                        }
                    </tbody>
                </table>
            </div>
        </div>
    );
}

export default HospitalPatients;
