import React, { useEffect, useState } from "react";
import {Link} from 'react-router-dom';

const HospitalDashboard = () =>{
    var total = 0;
    const [getDoctors, updategetDoctors] = useState([]);
    const [getStaff, updategetStaff] = useState([]);
    const [getPatients, updategetPatients] = useState([]);
    const [getBilling, updategetBilling] = useState([]);
    const [getPharma, updategetPharma] = useState([]);

    const getAllDoctors = () =>{
        let input={ 
            "id":localStorage.getItem("id"),
            "hospitalid": localStorage.getItem("hospitalid")
        };
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(input)
        };
        fetch('https://www.medicalplanet.in/webapi/Hospital/doctorforhospital', requestOptions)
        .then(response => response.json())
        .then(data =>{
            if(data.length>0){
                updategetDoctors(data); 
            }           
        });
    }

    const getAllStaff = () =>{
        let input = {
            "id":localStorage.getItem("id"),
            "hospitalid": localStorage.getItem("hospitalid")
        };
        const requestOptions = {
            method: 'POST',
            header : {'Content-Type' : 'application/json'},
            body : JSON.stringify(input)
        };
        fetch("https://www.medicalplanet.in/webapi/Hospital/stafflist", requestOptions)
        .then(response => response.json())
        .then(data =>{              
                if(data.length>0){
                    updategetStaff(data); 
                }
        });
    }

    const getAllPatients = () => {
        let input = {
            "hospitalid":localStorage.getItem("hospitalid"),
            "id":localStorage.getItem("id")
        };
        const requestOptions = {
            method: 'POST',
            header : {'Content-Type' : 'application/json'},
            body : JSON.stringify(input)
        };
        fetch("https://www.medicalplanet.in/webapi/Hospital/patientforhospital", requestOptions)
        .then(response => response.json())
        .then(data =>{              
                if(data.length>0){
                    updategetPatients(data); 
                }
        })
    }

    const getAllBilling = () =>{
        let input = {
            "doctorid": localStorage.getItem("id"),
            "hospitalid":localStorage.getItem("hospitalid"),
            // "bookingdate": pdate,
            "type": localStorage.getItem("type")
        };
        const requestOptions = {
            method: 'POST',
            header : {'Content-Type' : 'application/json'},
            body : JSON.stringify(input)
        };
        fetch("https://www.medicalplanet.in/webapi/Doctor/getpatientbill", requestOptions)
        .then(response => response.json())
        .then(data =>{  
            // console.log(data);
                if(data.length>0){
                    updategetBilling(data); 
                }
        })            
    }

    const getAllPharma = () =>{
        let input = {
            "hospitalid":localStorage.getItem("hospitalid"),
            "id":localStorage.getItem("id")
        };
        const requestOptions = {
            method: 'POST',
            header : {'Content-Type' : 'application/json'},
            body : JSON.stringify(input)
        };
        fetch("https://www.medicalplanet.in/webapi/Cart/myproduct", requestOptions)
        .then(response => response.json())
        .then(data =>{              
                if(data.length>0){
                    updategetPharma(data); 
                }
        })
    }

    useEffect(()=>{
        getAllDoctors();
        getAllStaff();
        getAllPatients();
        getAllBilling();
        getAllPharma();
    },[]);

    return(
        <div className="container">
            <div className="row mt-4">
                <div className="col-lg-12">
                    <h4 className="text-center text-primary"> <i className="fa fa-hospital-o"></i> Hospital Dashboard</h4>
                </div>
            </div>
            <div className="row mt-2">
                <div className="col-lg-2 text-center grow">
                    <Link to="/hospitalbilling/consulation" className="remove-line">
                        <div className="p-2 border rounded m-1 bg-success text-white">
                            <i className="fa fa-inr fa-lg icon-border text-success"></i>
                            <h5>Billing</h5>
                            {
                                getBilling.map((data, index) => {                                
                                    total += parseInt(data.fee);                                
                                })
                            }                        
                            <p><i className="fa fa-inr"></i> {total} <small>Today</small></p>                        
                        </div>
                    </Link>
                </div>
                <div className="col-lg-2 text-center grow">
                    <Link to="/hospitaldoctor" className="remove-line">
                        <div className="p-2 border rounded m-1 bg1">
                            <i className="fa fa-user-md fa-lg icon-border"></i>
                            <h5>Doctors</h5>
                            <p>{getDoctors.length}</p>
                        </div> 
                    </Link>
                </div>
                <div className="col-lg-2  text-center grow">
                    <Link to="/hospitalstaff" className="remove-line">
                        <div className="p-2 border rounded m-1 bg2">
                            <i className="fa fa-stethoscope fa-lg icon-border"></i>
                            <h5>Staff</h5>
                            <p>{getStaff.length}</p>
                        </div>
                    </Link>
                </div>
                <div className="col-lg-2 text-center grow">
                    <Link to="hospitalpatients" className="remove-line">
                        <div className="p-2 border rounded m-1 bg3">
                            <i className="fa fa-users fa-lg icon-border"></i>
                            <h5>Patients</h5>
                            <p>{getPatients.length}</p>
                        </div>
                    </Link>
                </div>
                <div className="col-lg-2 text-center grow">
                    <div className="p-2 border rounded m-1 bg4">
                        <i className="fa fa-ambulance fa-lg icon-border"></i>
                        <h5>Lab Tests</h5>
                        <p>77</p>
                    </div>
                </div>
                <div className="col-lg-2 text-center grow">
                    <Link to="/hospitalpharma" className="remove-line">
                        <div className="p-2 border rounded m-1 bg-primary text-white">
                            <i className="fa fa-medkit fa-lg icon-border text-primary"></i>
                            <h5>Pharma</h5>
                            <p>{getPharma.length} <small>medicines</small></p>                        
                        </div>
                    </Link>
                </div> 

                <div className="col-lg-2 text-center grow mt-4">
                    <Link to="/newpatient" className="remove-line">
                        <div className="p-4 border rounded m-1 bg-primary text-white">
                            <i className="fa fa-user-plus fa-lg icon-border text-primary"></i>
                            <h5>Register New Patient</h5>                      
                        </div>
                    </Link>
                </div>                
            </div>
           
        </div>
    );
}

export default HospitalDashboard;