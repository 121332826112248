import React, { useState,useEffect } from "react";
const DBookingSlots = () =>{
    let[msg, updateMsg] = useState("");
    let[absent, updateAbsent] = useState([]);
    let[Dstart, updateDstart] = useState("");
    let[Dend, updateDend] = useState("");
    let[Dinterval, updateDinterval] = useState("");


    let numbers = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24];
    let [weekdays, updateDays] = useState(
        ["Sunday","Monday","Tuesday","Wednesday","Thursday","Friday","Saturday"]
    );

    let[finalAbsent,updateFinalabsent] = useState([]);
    let[selectedday, pickOne] = useState();
   
    const processDay = () =>{
        updateAbsent(absent =>[...absent,weekdays[selectedday]]);
        updateFinalabsent(finalAbsent =>[...finalAbsent,selectedday]);
       
    }

    const ClearAll = () =>{
        updateAbsent([]);
        updateFinalabsent([]);
    }

    const createSlot = () =>{
        let input = {
            "doctorid": localStorage.getItem("id"),
            "absent": finalAbsent.toString(),
            "fromdate": "",
            "todate": "",
            "starttime": Dstart,
            "endtime": Dend,
            "interval": Dinterval
        };
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(input)
        };
        fetch('https://www.medicalplanet.in/webapi/Doctor/createslot', requestOptions)
            .then(response => response.text())
            .then(data => {
                updateMsg(data);
            });
    }

    const getDocProfile = () =>{
        
        let input={ "doctorid":localStorage.getItem("id"),
            "hospitalid": localStorage.getItem("hospitalid")
        };
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(input)
        };
        fetch('https://www.medicalplanet.in/webapi/Doctor/viewprofile', requestOptions)
        .then(response => response.json())
        .then(data =>{
            console.log(data);
            updateFinalabsent(data.absent);
            updateDstart(data.starttime);
            updateDend(data.endtime);
            updateDinterval(data.slottime);
            var myabset = data.absent.split(",");
           
            for(var i=0; i<myabset.length; i++){
                absent.push(weekdays[myabset[i]]);
                updateAbsent(absent =>[...absent]);
            }
            
            const uniqueSet = new Set(absent);
            updateAbsent(absent =>[...uniqueSet]);
            
        });
    }

    useEffect(()=>{
        getDocProfile();
    },[true])

    return(
       
        <div className="container mt-4">
            <div className="row">
                <div className="col-lg-12 mt-2">
                    <p className="text-center">{msg}</p>
                </div>
            </div>
            <div className="row mt-2">
                <div className="col-lg-6">
                    <div className="p-5 shadow rounded pt-2">
                        <h3 className="text-center text-info mb-4">Select Your Holidays </h3>
                        <div className="mb-3 row">
                        <div className="col-lg-8">
                            <select className="form-select" 
                            onChange={obj=>pickOne(obj.target.value)}>
                                <option value="">Choose Day</option>
                                {
                                    weekdays.map((day, index) =>{
                                        return(
                                            <option key={index} value={index}>{day}</option>
                                        )
                                    })
                                }
                            </select>
                        </div>
                        <div className="col-lg-4">
                           <button className="btn btn-primary" onClick={processDay}>+Add To Leave</button>
                        </div>
                    </div>
                        <div>
                            <h5 className="text-warning mt-4">Your Not Working Days Are</h5>
                            {
                                absent.map((name, index)=>{
                                    return(
                                        <span key={index} className="m-3 badge bg-danger p-2">
                                            <i className="fa fa-times"></i> {name}
                                        </span>
                                    )
                                })
                            }
                            <div className="text-center">
                            <button className="btn btn-warning mt-3" onClick={ClearAll}>
                            <i className="fa fa-refresh"></i>  Reset
                            </button>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-lg-6">
                    <div className="p-5 shadow rounded pt-2">
                    <h3 className="text-center text-info mb-4">Slots Booking Settings</h3>
                    <div className="mb-3 row">
                        <span className="col-lg-4">
                            Duty Start Time
                        </span>
                        <div className="col-lg-4">
                        <select className="form-select" onChange={obj=>updateDstart(obj.target.value)}>
                        {
                            numbers.map((index, number) =>{
                                return(
                                    <option key={index}>{number+1}</option>
                                )
                            })
                        }
                        </select>
                        </div>
                        <p className="col-lg-4">{Dstart} AM/PM</p>
                    </div>
                    <div className="mb-3 row">
                        <span className="col-lg-4">
                            Duty End Time
                        </span>
                    <div className="col-lg-4">
                    <select className="form-select" onChange={obj=>updateDend(obj.target.value)}>
                    {
                        numbers.map((index, number) =>{
                            return(
                                <option key={index}>{number+1}</option>
                            )
                        })
                    }
                    </select>
                    </div>
                    <p className="col-lg-4">{Dend} AM/PM</p>
                    </div>
                    <div className="mb-3 row">
                        <span className="col-lg-4">
                            Each Consultation
                        </span>
                        <div className="col-lg-4">
                    <select className="form-select" onChange={obj=>updateDinterval(obj.target.value)}>
                        <option>Select</option>
                        <option value="10">10 Minutes</option>
                        <option value="15">15 Minutes</option>
                        <option value="20">20 Minutes</option>
                        <option value="30">30 Minutes</option>
                    </select>
                    </div>
                    <p className="col-lg-4">{Dinterval} Minutes</p>
                </div>
                </div>
                </div>
            </div>

            <div className="row mt-4">
                <div className="mb-2 mt-2 text-center">
                    <button className="btn btn-success" onClick={createSlot}> 
                        <i className="fa fa-check"></i> Update Settings
                    </button>
                </div>
            </div>
        </div>
    );
}

export default DBookingSlots;