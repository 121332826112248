import React from "react";
import { Link } from "react-router-dom";

const DoctorHeader = () =>{
    return(
        <>        
        <nav className="navbar navbar-expand-md navbar-dark fixed-top navbarbg">
            <div className="container-fluid">
                <Link className="navbar-brand text-warning" to="/docprofile"><i className="fa fa-heartbeat "></i> Medical Planet</Link>
                <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarCollapse" aria-controls="navbarCollapse" aria-expanded="false" aria-label="Toggle navigation">
                <span className="navbar-toggler-icon"></span>
                </button>
                <div className="collapse navbar-collapse" id="navbarCollapse">        
                    <ul className="navbar-nav ms-auto mb-2 mb-md-0">
                    <li className="nav-item">
                        <Link className="nav-link active" aria-current="page" to="/docbookings"><i className="fa fa-stethoscope"></i> Waiting Consultation</Link>
                        </li>
                    <li className="nav-item">
                        <Link className="nav-link active" aria-current="page" to="/patientdetails"><i className="fa fa-users"></i> Completed</Link>
                        </li>
                        
                        <li className="nav-item">
                        <Link className="nav-link active" aria-current="page" to="/ddeleteslots"><i className="fa fa-check"></i> Booked Slots</Link>
                        </li>
                        <li className="nav-item">
                        <Link className="nav-link active" aria-current="page" to="/dbookingslots"><i className="fa fa-book"></i> Appointments Setting</Link>
                        </li>
                        
                        <li className="nav-item">
                        <Link className="nav-link active" aria-current="page" to="/docprofile"><i className="fa fa-address-card"></i> Profile</Link>
                        </li>
                        <li className="nav-item">                       
                            <a className="nav-link menu text-primary">
                                <i className="fa fa-power-off text-danger" onClick={logout}> Logout </i>
                            </a>
                        </li>
                    </ul>
                </div>
            </div>
        </nav>
<br/> <br/> <br />
    </>
    )
}

export default DoctorHeader;

const logout = () =>{
    localStorage.clear();
    window.location.reload();
}