import React, {useState, useEffect} from 'react';
import {Link} from 'react-router-dom';
import ReactPaginate from 'react-paginate';
import { ToastContainer, toast } from 'react-toastify';

const PER_PAGE = 5;

const HospitalPharma = () =>{
    // const options = [];
    const [allMedicines, setAllMedicines] = useState([]);   
    const [search, setSearch] = useState("");
    const [qty, setQty] = useState("");
    const [cartQty, setCartQty] =  useState(0);    

    const getAllMedicines = () =>{
        var input = {
            "id": localStorage.getItem("id"),
            "hospitalid": localStorage.getItem("hospitalid")
        };
        const requestOptions = {
            method : 'POST',
            headers : {'Content-Type' : 'application/json'},
            body : JSON.stringify(input)
        };
        fetch("https://www.medicalplanet.in/webapi/Cart/myproduct", requestOptions)
        .then(response => response.json())
        .then(data =>{
            // console.log(data);
            setAllMedicines(data);
        })
    }

    const clearAll = () =>{
        setSearch("");
        getAllMedicines();
    }

    const deleteMedicine = (medid) =>{
        var input = {
            "hospitalid":localStorage.getItem("hospitalid"),
            "id":localStorage.getItem("id"),
            "productid": medid
        };
        const requestOptions = {
            method : 'POST',
            headers : {'Content-Type' : 'application/json'},
            body : JSON.stringify(input)
        };
        fetch("https://www.medicalplanet.in/webapi/Cart/deleteone", requestOptions)
        .then(response => response.text())
        .then(data => {
            getAllMedicines();
            toast.error(data);
        })
    }

    const getAllCartCount = () =>{
        var input = {
            "hospitalid": localStorage.getItem("hospitalid"),
            "id": localStorage.getItem("id"),
            "pateintid": localStorage.getItem("pid"),
            "orderid": localStorage.getItem("orderid")
        };
        const requestOptions =  {
            method : 'POST',
            headers : {'Content-Type' : 'application/json'},
            body : JSON.stringify(input)
        };
        fetch("https://www.medicalplanet.in/webapi/Cart/getcartitem",requestOptions)
        .then(response => response.json())
        .then(data =>{
            if(data.length > 0){                                            
                setCartQty(data.length);
                // console.log("Data lenth " + data.length);
            }         
        })
    } 

    const addToCart = (mprodid, mprice, mqty ) =>{
            var pname = localStorage.getItem("pname");
            var pid = localStorage.getItem("pid");
            // console.log(pname + " " + pid);
            if(pname == null || pid == null) {
                toast.error("Select Patient");
            }        
            
            else{
                    var input = {
                        "hospitalid": localStorage.getItem("hospitalid"),
                        "id": localStorage.getItem("id"),
                        "productid": mprodid,
                        "qty": qty,
                        "price": mprice,
                        "pateintid": localStorage.getItem("pid"),
                        "orderid": localStorage.getItem("orderid")
                    };
                    const requestOptions = {
                        method : 'POST',
                        headers : {'Content-Type' : 'application/json'},
                        body : JSON.stringify(input)
                    };
                    fetch("https://www.medicalplanet.in/webapi/Cart/addtocart",requestOptions)
                    .then(response => response.text())
                    .then(data =>{                  
                        // setCartQty(cartQty+1);       
                        getAllCartCount();                
                    }) 
                }       
    }


    // const getDropdown = (n) =>{       
    //     const quantity = [];
    //     for (var i=1; i<=n; i++){
    //         quantity.push(i);
    //     }        
    //     // console.log(quantity);
    //     return(
    //     <select className='form-select w-50' onChange={obj=>setQty(obj.target.value)}>
    //         <option value="">Choose</option>
    //         {
    //             quantity.map((data)=><option key={data} value={data}>{data}</option>)                
    //         }
    //     </select>
    //     )        
    // }

    useEffect(()=>{
        getAllMedicines();
        getAllCartCount();
    }, []);


    const [currentPage, setCurrentPage] = useState(0);
    function handlePageClick({ selected: selectedPage }) {
        setCurrentPage(selectedPage)
    }
    const offset = currentPage * PER_PAGE;
    const pageCount = Math.ceil(allMedicines.length / PER_PAGE);

    // console.log("Total " + total);
    return(
        <div className='container'>
            <div className='row mt-4'>
                <div className='col-lg-2'>
                    <h4 className='text-primary'>Medicines: {allMedicines.length}</h4>
                </div>
                <div className='col-lg-5 text-center'>
                    <h4 className='text-success'>{localStorage.getItem("pname")}  {(localStorage.getItem("pid"))}</h4>
                </div>
                <div className='col-lg-3'>
                    <div className="input-group">
                        <span className='input-group-text bg-white'><i className='fa fa-search'></i></span>                        
                        <input type="text" className="form-control" placeholder="ID/Name/Description" onChange={obj=>setSearch(obj.target.value)} value={search} />
                        <button className=" btn btn-sm border bg-secondary" onClick={clearAll}><i className='fa fa-remove'></i></button>
                    </div>
                </div>                
                <div className='col-lg-1'>
                    {/* <Link to="/addnewmedicine" className='float-end'>        
                            <div className='icon-border1 text-center text-white bg-warning' data-bs-toggle="tooltip" data-bs-placement="left" title="Add New Medicine">
                                <i className='fa fa-plus fa-2x p-1'></i>
                            </div>                                          
                    </Link> */}
                </div>
                <div className='col-lg-1'>
                <Link to="/cartdetails">
                    <button className="btn btn-white position-relative">
                        <div className='text-center text-primary'>
                            <i className='fa fa-cart-plus fa-2x'></i>
                        </div>
                        <span className="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-danger">
                            {cartQty}
                            <span className="visually-hidden">unread messages</span>
                        </span>
                    </button> 
                </Link>                                      
                </div>
            </div>
            <div className='row mt-2'>
                <div className='col-lg-12'>
                    <table className='table table-bordered table-sm text-center'>
                        <thead>
                            <tr>                               
                                <th>ID</th>
                                <th>Name</th>
                                <th>Description</th>
                                <th>Quantity</th>
                                <th>Price/Unit</th>                                
                                <th>Buy</th>                            
                            </tr>
                        </thead>
                        <tbody>
                            {
                                allMedicines.filter(post =>{
                                    if((post.productname.toLowerCase().includes(search.toLowerCase())) || 
                                        (post.productid.includes(search)) ||
                                        (post.details.toLowerCase().includes(search.toLowerCase())) ){
                                            return post;
                                        }
                                }).slice(offset, offset + PER_PAGE).map((mdata, index)=>{
                                    return(
                                        <tr key={index}>                                            
                                            <td>{mdata.productid}</td>
                                            <td>{mdata.productname}</td>
                                            <td>{mdata.details}</td>
                                            <td>{mdata.quantity}</td>
                                            <td>{mdata.price}</td>                                            
                                            <td className='d-flex align-items-center justify-content-center'>     
                                                {/* {getDropdown(mdata.quantity)}*/}                                                
                                                    <input type="number" className='form-control textbox-width' placeholder='Qty' onChange={obj=>setQty(obj.target.value)} />                                                                                                   
                                                    <button className='btn btn-sm bg-warning m-1' data-bs-toggle="tooltip" data-bs-placement="right" title="Add to Cart" onClick={addToCart.bind(this,mdata.productid,mdata.price, mdata.quantity)}>
                                                    <i className='fa fa-plus fa-lg'></i> 
                                                    </button>                                                
                                            </td>
                                        </tr>
                                    )
                                })
                            }
                        </tbody>
                    </table>

                    <div className="mb-4 mt-4">
                        <ReactPaginate
                            previousLabel={"Previous"}
                            nextLabel={"Next"}
                            breakLabel={"..."}
                            pageCount={pageCount}
                            marginPagesDisplayed={2}
                            pageRangeDisplayed={3}
                            onPageChange={handlePageClick}
                            containerClassName={"pagination  justify-content-center"}
                            pageClassName={"page-item "}
                            pageLinkClassName={"page-link"}
                            previousClassName={"page-item"}
                            previousLinkClassName={"page-link"}
                            nextClassName={"page-item"}
                            nextLinkClassName={"page-link"}
                            breakClassName={"page-item"}
                            breakLinkClassName={"page-link"}
                            activeClassName={"active primary"}
                        />
                    </div>
                </div>
            </div>
            <ToastContainer position="top-right"
                    autoClose={2000}
                    hideProgressBar={false}
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover />
        </div>
    );
}

export default HospitalPharma;


// if(mqty == ""){
        //     toast.error("Select quantity");
        // }else if(mdata.quantity == 0 || mdata.quantity < mqty)
        // {
        //     toast.error("Insufficient Quantity");
        // }
        // else{
        //     console.log(mdata);
        //     console.log("Qty " + mqty);
        //     mdata.quantity = parseInt(mdata.quantity) - mqty;
        //     console.log("Subtract Qty " + mdata.quantity);
        //     setCartQty(cartQty+1);
        //     setTotal(total + (mqty * mdata.price));                   
        // }        