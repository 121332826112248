import React from "react";
import { Link, useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";
import { ToastContainer, toast } from "react-toastify";
//import ReactHTMLTableToExcel from 'react-html-table-to-excel';
//import Pdf from "react-to-pdf";

//const ref = React.createRef();
const options = {
    orientation: 'landscape',
    unit: 'in',
    format: [4, 2]
}

const CartDetails = () => {
    var sum = 0;
    var sum1 = 0;
    const [pcartList, setpCartList] = useState([]);
    const [paymode, setPaymode] = useState("");
    const [disabled, setDisabled] = useState(true);
    const [allQuantities, setAllQuantities] = useState([]);
    const navigate = useNavigate();

    const getCartItems = () => {
        var input = {
            "hospitalid": localStorage.getItem("hospitalid"),
            "id": localStorage.getItem("id"),
            "pateintid": localStorage.getItem("pid"),
            "orderid": localStorage.getItem("orderid")
        };
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(input)
        };
        fetch("https://www.medicalplanet.in/webapi/Cart/getcartitem", requestOptions)
            .then(response => response.json())
            .then(data => {
                if (data.length > 0) {
                    setpCartList(data);
                    // console.log(data);
                } else {
                    toast.error("No data in cart");
                }
            })
    }

    const paynow = () => {
        // console.log(paymode);      
        if (pcartList.length == 0) {
            toast.error("Add items to cart");
        }
        else if (paymode === "") {
            toast.error("Select Payment Mode");
        } else {
            var input = {
                "hospitalid": localStorage.getItem("hospitalid"),
                "id": localStorage.getItem("id"),
                "pateintid": localStorage.getItem("pid"),
                "orderid": localStorage.getItem("orderid"),
                "total": sum,
                "mode": paymode
            };
            const requestOptions = {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(input)
            };
            fetch("https://www.medicalplanet.in/webapi/Cart/paynow", requestOptions)
                .then(response => response.text())
                .then(data => {
                    toast.success(data);
                    setDisabled(false);
                    deductQuantity();
                })
        }
    }

    const deductQuantity = () => {

    }

    const printBill = () => {
        setpCartList([]);
        localStorage.removeItem("pid");
        localStorage.removeItem("orderid");
        localStorage.removeItem("pname");
        setDisabled(true);
        navigate("/hospitalbilling/pharma");
    }



    useEffect(() => {
        getCartItems();
    }, [])

    return (
        <div className="container">
            <div className="row mt-4">

            </div>
            <div className="row mt-2 gx-5">
                <div className="col-lg-12">
                    <div className="row mt-2 mb-3">
            
                        <div className="col-lg-4">
                            <h4 className="text-primary text-center">Cart Items For : {localStorage.getItem("pname")}({localStorage.getItem("pid")})</h4>
                        </div>
                        <div className="col-lg-4 ">
                            <Link to="/hospitalpharma" className="text-white">
                                <button className="btn btn-info btn-sm text-danger">
                                    <i className="fa fa-plus"></i> Add More Items
                                </button>
                            </Link>
                        </div>
                    </div>
                    <div className="row" >
                        <div className="col-lg-12">
                            <table className="table shadow rounded">
                                <thead>
                                    <tr className="bg-light text-primary">
                                        <th>Item No</th>
                                        <th>Product Name</th>
                                        <th>Price/Unit</th>
                                        <th>Quantity</th>
                                        <th>Total</th>
                                        <th>Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        pcartList.map((pdata, index) => {
                                            return (
                                                <tr key={index}>
                                                    <td>{pdata.productid}</td>
                                                    <td>{pdata.productname}</td>
                                                    <td>{pdata.priceperunit}</td>
                                                    <td>{pdata.quantity}</td>
                                                    <td>{pdata.total} /-</td>
                                                    <td>
                                                        <button className="btn btn-sm btn-danger"><i className="fa fa-trash"></i></button>
                                                    </td>
                                                </tr>
                                            )
                                        })
                                    }
                                    <tr>
                                        {
                                            pcartList.map(data => {
                                                sum += parseInt(data.total);
                                            })
                                        }
                                        <th colSpan={4} className="text-end">Grand Total</th>
                                        <td colSpan={2}>
                                            {sum}/-
                                        </td>
                                    </tr>

                                </tbody>
                            </table>

                        </div>
                    </div>

                </div>
                <div className="col-lg-12 mt-4">
                    <h4 className="text-info mb-3">Payment Options</h4>
                    <div className="row mb-2">
                        <div className="col-lg-2">
                            <input type="radio" className="form-check-input mb-3" value="card" name="paymentmode" onChange={obj => setPaymode(obj.target.value)} /> Debit/Credit Card
                            <br />
                            <input type="radio" className="form-check-input mb-3" value="upi" name="paymentmode" onChange={obj => setPaymode(obj.target.value)} /> UPI
                            <br />
                            <input type="radio" className="form-check-input mb-3" value="cash" name="paymentmode" onChange={obj => setPaymode(obj.target.value)} /> Cash
                        </div>
                        <div className="col-lg-6">
                            {
                                (() => {
                                    if (paymode === "") {
                                        return (
                                            <></>
                                        )
                                    }
                                    else if (paymode === "card") {
                                        return (
                                            <div className="card">
                                                <div className="card-header bg-light">
                                                    <h6>Debit/Credit Card Details</h6>
                                                </div>
                                                <div className="card-body">
                                                    <div className="m-1">
                                                        <input type="text" className="form-control" placeholder="Card Number" />
                                                    </div>
                                                    <div className="mt-2 d-flex">
                                                        <select className="form-select m-1 text-secondary">
                                                            <option>Month</option>
                                                        </select>
                                                        <select className="form-select m-1 text-secondary">
                                                            <option>Year</option>
                                                        </select>
                                                    </div>
                                                    <div className="mt-2 d-flex">
                                                        <input type="text" className="form-control m-1 w-50" placeholder="CVV" />
                                                        <small className="text-muted">Found on the signature strip</small>
                                                    </div>
                                                </div>
                                                <div className="card-footer">
                                                    <div className="text-center">
                                                        <button className="btn btn-primary m-1" onClick={paynow}>Pay Now</button>
                                                        <button className="btn btn-warning m-1" onClick={printBill} disabled={disabled}>Print Bill</button>
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    } else if (paymode === "upi") {
                                        return (
                                            <div className="card">
                                                <div className="card-header bg-light">
                                                    <h6>UPI Details</h6>
                                                </div>
                                                <div className="card-body">
                                                    <input type="text" className="form-control" placeholder="Enter UPI ID" />
                                                </div>
                                                <div className="card-footer">
                                                    <div className="text-center">
                                                        <button className="btn btn-primary m-1" onClick={paynow}>Pay Now</button>
                                                        <button className="btn btn-warning m-1" onClick={printBill} disabled={disabled}>Print Bill</button>
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    } else {
                                        return (
                                            <div className="card">
                                                <div className="card-header bg-light">
                                                    <h6>Collect Cash: {sum}/-</h6>
                                                </div>
                                                <div className="card-footer bg-light">
                                                    <div className="text-center">
                                                        <button className="btn btn-primary m-1" onClick={paynow}>Pay Now</button>
                                                        <button className="btn btn-warning m-1" onClick={printBill} disabled={disabled}>Print Bill</button>
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    }
                                })
                                    ()}
                        </div>
                    </div>

                </div>
            </div>

            <ToastContainer position="top-right"
                autoClose={2000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover />
        </div>
    )
}

export default CartDetails;
