import React, { useState } from "react";
import { Link } from "react-router-dom";


const Login = () =>{
    const [emailErr, setEmailErr] = useState("");
    const [passwordErr, setPasswordErr] = useState("");
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [message, updateMessage] = useState("");
    const [style, setStyle] = useState("");

    function loginCheck (){           
        if(email === "" && password === "")
        {
            setEmailErr("Email cannot be blank");
            setPasswordErr("Password cannot be blank");
        }
        else
        {   
            updateMessage("Please wait processing...");     
            let input={ 
                "email": email,
                "password": password
            };
                const requestOptions = {
                    method: 'POST',
                    headers: { 'Content-Type': 'application/json' },
                    body: JSON.stringify(input)
                };
                fetch('https://www.medicalplanet.in/webapi/Login/auth', requestOptions)
                .then(response => response.json())
                .then(data =>{
                    //  console.log(data);
                    if(data.status == "SUCCESS"){
                        setStyle("success");
                        localStorage.setItem("id", data.id);
                        localStorage.setItem("name", data.name);
                        localStorage.setItem("type", data.type);
                        localStorage.setItem("hospitalid", data.hospitalid);
                        window.location.reload();       // to reload same page
                    }else{
                        setStyle("failure");
                    }                
                    updateMessage(data.status + " : " + data.message);                
            });
        }
    }

    function emailHandler (e) {
        var emailRegEx = /^([a-zA-Z0-9_\-\.]+)@([a-zA-Z0-9_\-\.]+)\.([a-zA-Z]{2,5})$/;
        var item = e.target.value;
        // console.log("EMial " + item);
        if((!emailRegEx.test(item)) && (item !== ''))
        {
            setEmailErr("Invalid Email");
        }
        else{
            setEmailErr("");            
            setEmail(item);
        }          
    }

    function passwordHandler(e) {
        var item = e.target.value;
        // console.log("Password " + item);
        if((item.length < 3) && (item !== ''))
        {
            setPasswordErr("Password length should be greater than 3");
        }else{
            setPasswordErr("");
            setPassword(item);
        }         
    }

    return(
        <div className="container">
            <br/> 
            <div className="row text-center">
                <h3><i className="fa fa-heartbeat text-warning"></i> <span>Medical Planet</span> </h3>
            </div>
            <div className="row mt-3">
                <div className="col-lg-4 offset-4">
                    <div className="card rounded">
                        <div className="card-header p-3 bg-info text-white">
                            <h4><i className="fa fa-lock"></i> Login</h4>                            
                        </div>
                        <div className="card-body p-3">
                            <p className={style}>{message}</p>
                            <div className="input-group">
                                <span className="input-group-text"><i className="fa fa-envelope fa-1x"></i> </span>
                                <input type="email" className="form-control" placeholder="Email" required onChange={emailHandler}  />                                                                
                            </div>
                            <p className="text-danger text-small"><small>{emailErr}</small></p>
                            <div className="input-group">
                                <span className="input-group-text"><i className="fa fa-lock fa-1x"></i> </span>
                                <input type="password" className="form-control" placeholder="Password" required onChange={passwordHandler}  />
                            </div>
                            <p className="text-danger text-small"><small>{passwordErr}</small></p>
                            <div className="mt-3 mb-3 d-grid text-center">
                                <button className="btn btn-success" onClick={loginCheck} >Login <i className="fa fa-arrow-right"></i></button>  
                                <hr />
                                <Link to="#">Forgot Password?</Link>                                 
                            </div>                            
                        </div>                        
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Login;