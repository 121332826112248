import { useState, useEffect } from "react";
import { ToastContainer, toast } from "react-toastify";

const HospitalEditProfile = () =>{
    const [clinicName, updateClinicName] = useState("");
    const [clinicEmail, updateClinicEmail] = useState("");
    const [clinicMobile, updateClinicMobile] = useState("");
    const [clinicPassword, updateClinicPassword] = useState("");
    const [clinicAddress, udateClinicAddress] = useState("");
    const [clinicCity, updateClinicCity] = useState("");
    const [clinicState, updateClinicState] = useState("");
    const [clinicPincode, updateClinicPincode] = useState("");
    const [clinicActive, updateClinicActive] = useState("");


    const getClinicProfile = () =>{
        var input = {
            "hospitalid": localStorage.getItem("hospitalid"),
            "id": localStorage.getItem("id")
        };
        const requestOptions = {
            method : 'POST',
            headers: { 'Content-Type':'application/json'},
            body: JSON.stringify(input)
        };
        fetch("https://www.medicalplanet.in/webapi/Hospital/details", requestOptions)
        .then(response=>response.json())
        .then(data=>{
            // console.log(data);
            updateClinicName(data.name);
            updateClinicEmail(data.email);
            updateClinicMobile(data.mobile);
            udateClinicAddress(data.address);
            updateClinicCity(data.city);
            updateClinicState(data.state);
            updateClinicPincode(data.pincode);
            updateClinicPassword(data.password);
            updateClinicActive(data.active);
        })
    }

    const updateClinicProfile = () =>{       
        var mobileRegEx = /^[6-9]\d{9}$/;
        var nameRegEx = /[a-zA-Z]$/;

        if(clinicName==="" || clinicMobile==="" || clinicCity==="" || clinicAddress==="" || clinicPincode==="" || clinicState===""){
            toast.error("Values cannot be blank");
        }else if(!mobileRegEx.test(clinicMobile)){
            toast.error("Enter Mobile number in proper format");
        }else if(!nameRegEx.test(clinicCity) || !nameRegEx.test(clinicState) ){
            toast.error("Enter State/City in proper format");
        }
        else{
        var input = {
                'name': clinicName,
				// 'email': clinicEmail,
				'mobile': clinicMobile,
				// 'password': clinicPassword,
				'city': clinicCity,
				'address': clinicAddress,
				'pincode': clinicPincode,
				'state': clinicState,
				'active': clinicActive,
				'hospitalid': localStorage.getItem("hospitalid"),
                "id": localStorage.getItem("id")
        };
        const requestOptions = {
            method : 'POST',
            headers : {'Content-Type' : 'application/json'},
            body : JSON.stringify(input)
        };
        fetch("https://www.medicalplanet.in/webapi/Hospital/update", requestOptions)
        .then(response => response.text())
        .then(data => {
            toast.success(data);
            window.location.href = "http://localhost:3000/#/hospitalprofile";
        })
    }
    }

    useEffect(()=>{
        getClinicProfile();
    }, [])
  

    return(
        <div className="container mt-4">
            <div className="row">
                <h4 className="text-center">Edit Clinic Profile</h4>
                <div className="col-lg-2"></div>
                <div className="col-lg-4">
                    <div>
                        <label>Clinic Name</label>
                        <input type="text" className="form-control" value={clinicName} onChange={obj=>updateClinicName(obj.target.value)} />
                    </div>
                    {/* <div className="mt-2">
                        <label>Email</label>
                        <input type="email" className="form-control" value={clinicEmail} onChange={obj=>updateClinicEmail(obj.target.value)} />
                    </div> */}
                    <div className="mt-2">
                        <label>Mobile</label>
                        <input type="text" className="form-control" value={clinicMobile} onChange={obj=>updateClinicMobile(obj.target.value)} />
                    </div>
                    {/* <div className="mt-2">
                        <label>Password</label>
                        <input type="password" className="form-control" value={clinicPassword} onChange={obj=>updateClinicPassword(obj.target.value)} />
                    </div> */}
                    <div className="mt-2">
                        <label>State</label>
                        <input type="text" className="form-control" value={clinicState} onChange={obj=>updateClinicState(obj.target.value)} />
                    </div>
                    <div className="mt-2">
                        <label>Active</label>
                        <select className="form-select" value={clinicActive} onChange={obj=>updateClinicActive(obj.target.value)}>                                                      
                            <option value="NO">NO</option>
                            <option value="YES">YES</option>
                        </select>
                    </div>
                </div>
                <div className="col-lg-4">
                    <div>
                        <label>Address</label>
                        <textarea className="form-control" rows={4} value={clinicAddress} onChange={obj=>udateClinicAddress(obj.target.value)}></textarea>
                    </div>
                    <div className="mt-2">
                        <label>City</label>
                        <input type="text" className="form-control" value={clinicCity} onChange={obj=>updateClinicCity(obj.target.value)} />
                    </div>
                    
                    <div className="mt-2">
                        <label>Pincode</label>
                        <input type="text" className="form-control" value={clinicPincode} onChange={obj=>updateClinicPincode(obj.target.value)} />
                    </div>
                </div>
                <div className="col-lg-2"></div>
            </div>
            <div className="row text-center mt-3">
                <div className="col-lg-6 offset-3">
                    <button className="btn btn-primary" onClick={updateClinicProfile}><i className="fa fa-edit"></i> Update</button>
                </div>
            </div>
            <ToastContainer position="top-right"
                    autoClose={1000}
                    hideProgressBar={false}
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover />
        </div>
        
    );
}

export default HospitalEditProfile;