import React, {useState, useEffect} from "react";
import {Link} from "react-router-dom";
const ConsultationBilling = () =>{
    var total = 0;
    const [getBilling, updategetBilling] = useState([]);
   

    const getAllBilling = () =>{
        let input = {
            "doctorid": localStorage.getItem("id"),
            "hospitalid":localStorage.getItem("hospitalid"),
            // "bookingdate": pdate,
            "type": localStorage.getItem("type")
        };
        const requestOptions = {
            method: 'POST',
            header : {'Content-Type' : 'application/json'},
            body : JSON.stringify(input)
        };
        fetch("https://www.medicalplanet.in/webapi/Doctor/getpatientbill", requestOptions)
        .then(response => response.json())
        .then(data =>{  
            // console.log(data);
                if(data.length>0){
                    updategetBilling(data); 
                }
        })            
    }

    useEffect(()=>{
        getAllBilling();
    },[])

    return(
        <div className="container mt-4">
            <div className="row">
                    <div className="col-lg-12 text-end">
                        <div className="btn-group">
                            <Link className="btn btn-success" to="/hospitalbilling/consulation">
                            <i className="fa fa-user-md"></i> Consultation Billing
                            </Link>
                            <Link className="btn btn-primary"  to="/hospitalbilling/pharma">
                            <i className="fa fa-medkit"></i> Pharma Billing
                            </Link>
                        </div>
                    </div>
                </div>
            <div className="row">
                <div className="col-lg-12 mt-4">
                    <h4 className="text-center text-primary">Today's Doctor Consultation Billing</h4>
                    <table className="table table-bordered mt-4 shadow rounded">
                        <thead>
                            <tr className="bg-light text-primary">
                                <th>PID</th>
                                <th>Patient Name</th>
                                <th>Mobile</th>
                                <th>Doctor Name</th>
                                <th className="text-end">Amount</th>
                                <th>Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                getBilling.map((pdata, index)=>{
                                    return(
                                        <tr key={index}>
                                            <td>{pdata.pid}</td>
                                            <td>{pdata.name}</td>
                                            <td>{pdata.mobile}</td>
                                            <td>{pdata.doctorname}</td>
                                            <td className="text-end">{pdata.fee} /-</td>
                                            <td>
                                            <a href={`https://www.medicalplanet.in/webapi/Cart/consultationbill/${pdata.lastpaid}/${pdata.pid}`} target="_blank"><i className="fa fa-download"></i> Download</a>
                                            </td>
                                        </tr>
                                    )
                                })
                            }                                
                            <tr>     
                                {
                                    getBilling.map((data, index) => {
                                        total += parseInt(data.fee);
                                    })
                                }                           
                                <th className="text-end bg-light" colSpan={4}><h5>Total</h5></th>
                                <td className="bg-light text-end"><h5><i className="fa fa-inr"></i> {total} /-</h5></td>
                                <th className="bg-light text-end"></th>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    );
}

export default ConsultationBilling;

