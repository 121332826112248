import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";

const AllPatientDetails = () => {
    const navigate = useNavigate();

    const [allPDetails, updateAllPDetails] = useState([]);
    const [setQ, setQuery] = useState("");

    const getAllPatientDetails = () => {
        var input = {
            "id": localStorage.getItem("id"),
            "hospitalid": localStorage.getItem("hospitalid")
        };
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(input)
        };
        fetch("https://www.medicalplanet.in/webapi/Hospital/patientforhospital", requestOptions)
            .then(response => response.json())
            .then(data => {
                // console.log(data);
                updateAllPDetails(data);
            })
    }

    const clearPatient = () => {
        setQuery("");
        getAllPatientDetails();
    }

    const gotoPharma = (pid, pname) => {
        localStorage.setItem("pid", pid);
        localStorage.setItem("pname", pname);
        const current = new Date();
        const date = current.getFullYear() + "-" + (current.getMonth() + 1) + "-" + current.getDate();
        const time = current.getHours() + '-' + current.getMinutes() + '-' + current.getSeconds();
        const orderid = pid + "-" + date + "-" + time;
        localStorage.setItem("orderid", orderid);
        navigate("/hospitalpharma");
        // Order id format - pid-2022-06-27-11-02-30
        // window.location.href="http://localhost:3000/#/hospitalpharma";
    }

    useEffect(() => {
        getAllPatientDetails();
    }, []);


    return (
        <div className="container ">
            <div className="row mt-5">
                <div className="col-lg-4">
                    <h4 className="text-center text-primary mb-3">Manage Patients : {allPDetails.length}</h4>
                </div>
                <div className="col-lg-5"></div>
                <div className="col-lg-3">
                    <div className="input-group">
                        <input type="search" className="form-control p-1 " onChange={obj => setQuery(obj.target.value)} value={setQ} placeholder="PID/Name/Mobile" />
                        <button className="btn btn-warning btn-sm p-2" onClick={clearPatient}><i className="fa fa-remove"></i> Clear</button>
                    </div>
                </div>
            </div>
            <div className="row mt-3">
                <table className="table shadow rounded table-sm">
                    <thead>
                        <tr className="bg-light text-primary p-2">
                            <th>PID</th>
                            <th>Name</th>
                            <th>Doctor</th>
                            <th>Fee Paid</th>
                            <th>Diagnosis</th>
                            <th>Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            allPDetails.filter(post => {
                                if ((post.name.toLowerCase().includes(setQ.toLowerCase())) ||
                                    (post.pid.includes(setQ)) || (post.mobile.includes(setQ))) {
                                    return post;
                                }
                            }).map((pdata, index) => {
                                return (
                                    <tr key={index}>
                                        <td>{pdata.pid}</td>
                                        <td>{pdata.name} - {pdata.mobile} - {pdata.email}</td>
                                        <td>{pdata.doctorname}</td>
                                        <td>{pdata.lastpaidamount}</td>
                                        <td>{pdata.diagnosis}</td>
                                        <td>
                                            <Link to="/hospitalpharma">
                                                <button className="btn btn-success btn-sm m-2" onClick={gotoPharma.bind(this, pdata.pid, pdata.name)}>
                                                    <i className="fa fa-shopping-cart"></i> Buy
                                                </button>
                                                <button className="btn btn-warning btn-sm m-2">
                                                    <Link to={`/editappointment/${pdata.pid}`} className="remove-line"><i className="fa fa-edit fa-lg text-danger"></i></Link>
                                                </button>
                                            </Link>
                                        </td>
                                    </tr>
                                )
                            })
                        }
                    </tbody>
                </table>
            </div>
        </div>
    );
}

export default AllPatientDetails;