import React from "react";
import { HashRouter, Routes, Route } from "react-router-dom";
import ClinicHeader from "./clinicheader";

import HospitalDashboard from "../Hospital/hospitaldashboard";
import HospitalDoctor from "../Hospital/hospitaldoctor";
import HospitalStaff from "../Hospital/hospitalstaff";
import HospitalLabs from '../Hospital/hospitallabs.js';
import HospitalPharma from '../Hospital/hospitalpharma.js';
import HospitalPatients from "../Hospital/hospitalpatients";
import PharmaBill from '../Hospital/pharmabill';
import ConsultationBilling from '../Hospital/consultationbilling';
import EditAppointment from '../Staff/editAppointment.js';
import Appointments from "../Staff/appoint";
import NewPatient from "../Staff/newpatient";
import PatientDetails from "../Doctor/patientdetails";
import AllPatientDetails from "../Hospital/hospitalallpatients";

import HospitalProfile from "../Hospital/hospitalprofile";
import HospitalEditProfile from "../Hospital/hospitaleditprofile";

import CartDetails from "../Hospital/cartdetails";

import AddNewMedicine from "../Hospital/addnewmedicine";


const ClinicApp = () =>{
    return(
        <HashRouter>
            <ClinicHeader />            
            <Routes>
                <Route exact path="/" element={<HospitalDashboard />} />
                <Route exact path="/hospitaldoctor" element={<HospitalDoctor />} />
                <Route exact path="/hospitalstaff" element={<HospitalStaff />} />
                <Route exact path="/hospitallabs" element={<HospitalLabs />} />
                <Route exact path="/hospitalpharma" element={<HospitalPharma />} />
                <Route exact path="/hospitalpatients" element={<HospitalPatients />} />
                <Route exact path="/hospitalbilling/consulation" element={<ConsultationBilling/>}/>
                    <Route exact path="/hospitalbilling/pharma" element={<PharmaBill/>}/>

                <Route exact path="/editappointment/:pid" element={<EditAppointment />} />

                <Route exact path="/appointments" element={<Appointments />} />                             
               <Route exact path="/newpatient" element={<NewPatient />} />

               <Route exact path="/patientdetails" element={<PatientDetails />} />
               <Route exact path="/allpatientdetails" element={<AllPatientDetails />} />

               <Route exact path="/hospitalprofile" element={<HospitalProfile />} />
               <Route exact path="/hospitaleditprofile" element={<HospitalEditProfile />} />
             
                <Route exact path="/cartdetails" element={<CartDetails />} />
                <Route exact path="/addnewmedicine" element={<AddNewMedicine />} />                
                {/* <Route exact path="/docprofile" element={<DocProfile />} />
                <Route exact path="/docProfileEdit" element={<EditDoctorProfile />} /> */}
                {/* <Route exact path="/docbookings" element={<DocBookings />} /> */}
                {/* <Route exact path="/dbookingslots" element={<DBookingSlots />} /> */}                
                {/* <Route exact path="/ddeleteslots" element={<DocDeleteSlots />} />      */}
            </Routes>
        </HashRouter>
    )
}

export default ClinicApp;