import React from "react";
import { HashRouter, Routes, Route } from "react-router-dom";

import DocProfile from "./docProfile";
import DoctorHeader from "./doctorheader";
import EditDoctorProfile from "./editdProfile.js";
import DocBookings from "./docBookings";
import DBookingSlots from "./dbookingSlots";
import PatientDetails from "./patientdetails";
import DocDeleteSlots from "./ddeleteslots";

import Paginate from "./paginate";


const DoctorApp = () =>{
    return(
        <HashRouter>
        <DoctorHeader />
        <Routes>
            <Route exact path="/" element={<DocProfile />} />
            <Route exact path="/docprofile" element={<DocProfile />} />
            <Route exact path="/docProfileEdit" element={<EditDoctorProfile />} />
            <Route exact path="/docbookings" element={<DocBookings />} />
            <Route exact path="/dbookingslots" element={<DBookingSlots />} />
            <Route exact path="/patientdetails" element={<PatientDetails />} />
            <Route exact path="/ddeleteslots" element={<DocDeleteSlots />} />           
            
            {/* <Route exact path = "/paginate" element={<Paginate />} /> */}
        </Routes>
        </HashRouter>
    )
}

export default DoctorApp;