import { type } from "@testing-library/user-event/dist/type";
import React, { useEffect, useState } from "react";
import {ToastContainer, toast } from "react-toastify";

const HospitalStaff = () =>{
    const [allStaff, setAllStaff] = useState([]);
    const [sname, setsName] = useState("");
    const [smobile, setsMobile] = useState("");
    const [semail, setsEmail] = useState("");
    const [stype, setsType] = useState("");
    const [sprofile, setsProfile] = useState("");


    const getAllStaff = () => {
        let input = {
            "hospitalid": localStorage.getItem("hospitalid"),
            "id": localStorage.getItem("id")
        };
        const requestOptions = {
            method: 'POST',             // send data from frontend to backend, fully secured.
            header : {'Content-Type' : 'application/json'},     // what type of data we are sending? its json
            body : JSON.stringify(input)                // array to json
        };
        fetch("https://www.medicalplanet.in/webapi/Hospital/stafflist", requestOptions)
        .then(response => response.json())
        .then(data =>{              
                if(data.length>0){
                    // console.log(data);
                    setAllStaff(data); 
                }
        })
    }

    const saveStaff = () =>{
        // updateMessage1("Please Wait Processing...");
        var emailRegEx = /^([a-zA-Z0-9_\-\.]+)@([a-zA-Z0-9_\-\.]+)\.([a-zA-Z]{2,5})$/;
        var mobileRegEx = /^[6-9]\d{9}$/;
        var nameRegEx = /[a-zA-Z]$/;
        if(sname === "" || semail === "" || smobile === "" || sprofile === "" || stype === "")
        {
            toast.error("Please enter all details");
        }else if(!nameRegEx.test(sname)){
            toast.error("Name cannot contain numbers and special characters");
        }
        else if(!emailRegEx.test(semail)){
            toast.error("Enter Email in proper format");
        }else if(!mobileRegEx.test(smobile)){
            toast.error("Enter Mobile number in proper format");
        }
        else{
            let input = {
                "name": sname,
                "email": semail,
                "mobile": smobile,           
                "address": sprofile,               
                "type": stype,
                "id": localStorage.getItem("id"),
                "hospitalid": localStorage.getItem("hospitalid")
            };
            const requestOptions = {
                method : 'POST',
                header : {'Content-Type' : 'application/json' },
                body : JSON.stringify(input) 
            };
            fetch("https://www.medicalplanet.in/webapi/Hospital/savestaff", requestOptions)
            .then(response => response.text())
            .then(data =>{            
                toast.success(data);
                setsName(""); 
                setsMobile("");   
                setsEmail("");    
                setsProfile("");
                setsType("");    
                getAllStaff();
            })
        }        
    }

    const clearStaff = () =>{
        setsName(""); 
        setsMobile("");   
        setsEmail("");    
        setsProfile("");
        setsType(""); 
    }

    const deleteStaff = (staffid, type, email) =>{
        let input = {
            "hospitalid": localStorage.getItem("hospitalid"),
            "id": localStorage.getItem("id"),
            "staffid":staffid,
            "type": type,
            "email": email
        };
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(input)
        };
        fetch("https://www.medicalplanet.in/webapi/Hospital/deletestaff", requestOptions)
            .then(response => response.text())
            .then(data => {                
                toast.success(data);
                setAllStaff([]);
                getAllStaff();
            })
    }

    useEffect(()=>{
        getAllStaff();
    }, []);

    return(
        <div className="container mt-4">
            <div className="row">
                <h4 className="text-center">Staff Management</h4>
                {/* <p className="text-center text-success">{message1}</p> */}
                <div className="col-lg-2">
                    <div className="form-group">
                        <label>Name</label>
                        <input type="text" className="form-control" onChange={obj=>setsName(obj.target.value)} value={sname} />
                    </div>
                </div>
                <div className="col-lg-2">
                    <div className="form-group">
                        <label>Mobile</label>
                        <input type="text" className="form-control" onChange={obj=>setsMobile(obj.target.value)} value={smobile} />
                    </div>
                </div>
                <div className="col-lg-2">
                    <div className="form-group">
                        <label>Email</label>
                        <input type="email" className="form-control" onChange={obj=>setsEmail(obj.target.value)} value={semail} />
                    </div>
                </div>
                <div className="col-lg-2">
                    <div className="form-group">
                        <label>Type</label>
                        <select className="form-select" onChange={obj=>setsType(obj.target.value)} value={stype}>
                            <option value="">Choose</option>
                            <option value="DOCTOR">Doctor</option>
                            <option value="STAFF">Billing Staff</option>
                            <option value="PHARMA">Pharma Staff</option>
                            <option value="LAB">Lab Medical Staff</option>
                        </select>  
                        {/* <label>Password</label>
                        <input type="text" className="form-control" onChange={obj=>setsPassword(obj.target.value)} value={spassword} /> */}
                    </div>
                </div>
                <div className="col-lg-2">
                    <div className="form-group">
                        <label>About Profile</label>
                        <input type="text" className="form-control" onChange={obj=>setsProfile(obj.target.value)} value={sprofile} />              
                    </div>
                </div>
                <div className="col-lg-2 p-3">
                    <button className="btn btn-primary m-2" onClick={saveStaff}>Save</button>
                    <button className="btn btn-warning m-2" onClick={clearStaff}>Clear</button>
                </div>
            </div>

            <div className="row">
                <h6 className="text-center">Available Staff - {allStaff.length}</h6>
                {/* <p className="text-center text-danger">{message2}</p>                 */}
                <table className="table table-sm shadow rounded">
                    <thead>
                        <tr className="text-primary bg-light">
                            <th>Sl. No.</th>
                            <th>Staff Name</th>
                            <th>Mobile No</th>
                            <th>E-Mail Id</th>
                            <th>Password</th>
                            <th>Type</th>
                            <th className="text-center">Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            allStaff.map((sdata, index)=>{
                                return(
                                    <tr key={index}>
                                        <td>{index + 1}</td>
                                        <td>{sdata.name}</td>
                                        <td>{sdata.mobile}</td>
                                        <td>{sdata.email}</td>
                                        <td>{sdata.password}</td>
                                        <td>{sdata.profile}</td>
                                        <td className="text-center">
                                            <button className="btn btn-danger btn-sm" onClick={deleteStaff.bind(this, sdata.staffid, sdata.type, sdata.email)}><i className="fa fa-trash"></i> </button>
                                        </td>
                                    </tr>
                                )
                            })
                        }
                    </tbody>
                </table>
            </div>
            <ToastContainer position="top-right"
                    autoClose={2000}
                    hideProgressBar={false}
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover />
        </div>
    );
}

export default HospitalStaff;