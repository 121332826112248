import React from "react";
import {Link} from 'react-router-dom';

const ClinicHeader = () =>{
    return(  
        <>
        <nav className="navbar navbar-expand-md navbar-dark fixed-top navbarbg mb-5">
            <div className="container-fluid">
                <Link className="navbar-brand text-warning" to="/"><i className="fa fa-heartbeat"></i> Medical Planet</Link>
                <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarCollapse" aria-controls="navbarCollapse" aria-expanded="false" aria-label="Toggle navigation">
                <span className="navbar-toggler-icon"></span>
                </button>
                <div className="collapse navbar-collapse" id="navbarCollapse">        
                    <ul className=" navbar-nav ms-auto mb-2 mb-md-0 ">
                        <li className="nav-item mr-1">
                        <Link className="nav-link active" to="/"><i className="fa fa-dashboard"></i> Dashboard</Link>
                        </li>            
                        {/* Dropdown - Patient Zone */}
                        <li className="nav-item dropdown mr-2">
                            <a className="nav-link dropdown-toggle active" href="javascript:void(0)" role="button" id="navbarDropdownMenuLink" data-bs-toggle="dropdown" aria-expanded="false">
                                <i className="fa fa-users"></i> Patient Zone
                            </a>
                            <ul className="dropdown-menu m-0 p-0 ulwidth" aria-labelledby="navbarDropdownMenuLink" style={{backgroundColor:"#0c4379"}} >                                
                                <Link to="/newpatient"  className="removehover" >
                                    <li className="nav-item lihover text-white ">
                                        <i className="fa fa-plus-square"></i> Register New Patient
                                    </li>        
                                </Link>        
                                <Link className="removehover"  to="/appointments">
                                    <li className="nav-item lihover text-white">
                                        <i className="fa fa-calendar"></i> Today Appointments
                                    </li>    
                                </Link>                            
                                {/* <li className="nav-item lihover">
                                    <Link className="removehover text-white" aria-current="page" to="/docbookings"><i className="fa fa-stethoscope"></i> Waiting Consultation</Link>
                                </li>                                */}

                                <Link className="removehover" to="/patientdetails">
                                    <li className="nav-item lihover text-white">
                                        <i className="fa fa-users"></i> Completed Consultation
                                    </li>  
                                </Link>
                                <Link className="removehover " to="/allpatientdetails">
                                    <li className="nav-item lihover text-white">
                                        <i className="fa fa-users"></i> All Patients
                                    </li>
                                </Link>
                            </ul>
                        </li>
                        
                        {/* Dropdown - Accounts */}
                        <li className="nav-item dropdown mr-2">
                            <a className="nav-link dropdown-toggle active" href="javascript:void(0)" role="button" id="navbarDropdownMenuLink" data-bs-toggle="dropdown" aria-expanded="false">
                                <i className="fa fa-user-md"></i> Accounts
                            </a>
                            <ul className="dropdown-menu m-0 p-0 ulwidth1" aria-labelledby="navbarDropdownMenuLink" style={{backgroundColor:"#0c4379"}}>                                
                                <Link className="removehover" to="/hospitaldoctor">
                                    <li className="nav-item lihover text-white">
                                        <i className="fa fa-user-md"></i> Doctor
                                    </li>
                                </Link>
                                {/* <li><hr class="dropdown-divider"/></li> */}
                                <Link className="removehover" to="/hospitalstaff">
                                    <li className="nav-item lihover text-white">
                                        <i className="fa fa-stethoscope"></i> Staff
                                    </li>
                                </Link>
                                {/* <li><hr class="dropdown-divider"/></li> */}
                                <Link className="removehover" to="/hospitalbilling/consulation">
                                    <li className="nav-item lihover text-white">
                                        <i className="fa fa-inr"></i> Billing
                                    </li>
                                </Link>
                            </ul>
                        </li>
                        
                        <li className="nav-item mr-1">
                            <Link className="nav-link active" to="/hospitallabs"><i className="fa fa-flask"></i> Labs</Link>
                        </li>

                        <li className="nav-item mr-1">
                            <Link className="nav-link active" to="/hospitalpharma"><i className="fa fa-medkit"></i> Pharma</Link>
                        </li>

                        {/* Dropdown - Settings */}
                        <li className="nav-item dropdown mr-2">
                            <a className="nav-link dropdown-toggle active" href="javascript:void(0)" role="button" id="navbarDropdownMenuLink" data-bs-toggle="dropdown" aria-expanded="false">
                                <i className="fa fa-gears"></i> Settings
                            </a>
                            <ul className="dropdown-menu m-0 p-0 ulwidth2" aria-labelledby="navbarDropdownMenuLink" style={{backgroundColor:"#0c4379"}}>                                
                                <Link className="removehover" to="/hospitalprofile">
                                    <li className="nav-item lihover text-white">
                                        <i className="fa fa-address-card"></i> Clinic Profile
                                    </li>          
                                </Link>
                                <Link className="removehover" to="/addnewmedicine">
                                    <li className="nav-item lihover text-white">
                                        <i className="fa fa-plus"></i> Pharma inventory
                                    </li>          
                                </Link>                      
                                {/* <li className="nav-item lihover">
                                    <Link className="removehover text-white" aria-current="page" to="/ddeleteslots"><i className="fa fa-remove"></i> Cancel Slots</Link>
                                </li>                                */} 
                                {/* <li className="nav-item lihover">
                                    <Link className="removehover text-white" aria-current="page" to="/docprofile"><i className="fa fa-edit"></i> Edit Profile</Link>
                                </li> */}
                            </ul>
                        </li>                                            

                        <li className="nav-item mr-2">                       
                            <Link className="nav-link menu active text-warning" to="/"><b><i>{localStorage.getItem("name")}</i></b>  <i className="fa fa-power-off text-danger" onClick={logout}> Logout </i>
                            </Link>
                        </li>                        
                    </ul>
                </div>
            </div>
        </nav>
        <br/> <br />
        </>         
    );
}

export default ClinicHeader;

const logout = () =>{
    localStorage.clear();
    window.location.href="https://clinic.medicalplanet.in/";
    window.location.reload();
}

