import React, { useEffect, useState } from "react";


const PatientDetails = () =>{

    // const [pdate, updatepDate] = useState("");
    const [plist, updatepList] = useState([]);

    const getPatientDetails = () =>{
        let input = {
            "doctorid": localStorage.getItem("id"),
            "hospitalid":localStorage.getItem("hospitalid"),
            // "bookingdate": pdate,
            "type": localStorage.getItem("type")
        };
        const requestOptions = {
            method: 'POST',
            headers: {'Content-Type' : 'application/json'},
            body: JSON.stringify(input)
        };
        fetch("https://www.medicalplanet.in/webapi/Doctor/getpatient", requestOptions)
        .then(response => response.json())
        .then(data =>{
            // console.log(data);
            updatepList(data);
        })
    }

    useEffect(()=>{
        getPatientDetails();
    },[1])

    const [setQ, setQuery] = useState(""); 

    return(
        <div className="container mt-4">
            <div className="row">
                <div className="col-lg-7">
                    <h2 className="text-center text-info">{plist.length} : Completed Consultation</h2>             
                </div>
                <div className="col-lg-4">
                    <div className="input-group pt-2">
                    <span className="input-group-text">Search</span>
                        <input type="search" className="form-control p-1" 
                        onChange={obj=>setQuery(obj.target.value)} 
                        value={setQ} placeholder="PID/Name/Mobile"/>
                    </div>          
                </div>
                <div className="col-lg-1"></div>
            </div>
            
            <div className="row mt-4">
                <div className="col-lg-12">
                    <table className="table shadow rounded">
                        <thead>
                            <tr className="bg-light text-primary">
                                <th>ID</th>
                                <th>Patient</th>
                                <th>Consulted</th>
                                <th>By Doctor</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                plist.filter(post => {
                                    if((post.name.toLowerCase().includes(setQ.toLowerCase())) || 
                                    (post.pid.includes(setQ)) || (post.mobile.includes(setQ))){
                                        return post;
                                    }                            
                                    }).map((pdata, index)=>{
                                    return(
                                        <tr key={index}>
                                            <td>{pdata.pid}</td>
                                            <td>
                                                <div>{pdata.name}</div>
                                                <div>{pdata.mobile}</div>
                                                <div>{pdata.email}</div>
                                            </td>
                                            <td className="text-danger">
                                                {pdata.flag}
                                                <div><small>Last Visit - {pdata.lastvisit}</small></div>
                                                <div><small>Fee - {pdata.fee}</small></div>
                                            </td>
                                            <td>
                                                <div><b>Doctor : </b> {pdata.doctorname}</div>
                                                <div><b>Symptoms : </b> {pdata.symptoms}</div>
                                                <div> <b>Diagnosis : </b> {pdata.diagnosis}</div>
                                                <div> <b>Medicine : </b> {pdata.medicine}</div>
                                            </td>
                                        </tr>
                                    )
                                })
                            }
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    );
}

export default PatientDetails;