import React from "react";
import {Link} from 'react-router-dom';

const LoginHeader = () =>{
    return(  
        <>
        <nav className="navbar navbar-expand-md navbar-dark fixed-top navbarbg mb-5">
            <div className="container-fluid">
                <Link className="navbar-brand text-white" to="/"><i className="fa fa-heartbeat text-warning"></i> Medical Planet</Link>
                <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarCollapse" aria-controls="navbarCollapse" aria-expanded="false" aria-label="Toggle navigation">
                <span className="navbar-toggler-icon"></span>
                </button>
                <div className="collapse navbar-collapse" id="navbarCollapse">        
                    <ul className="navbar-nav ms-auto mb-2 mb-md-0">
                        <li className="nav-item">
                        <Link className="nav-link active" aria-current="page" to="/"> New to Medical Planet? <i className="fa fa-user-plus"></i> Register</Link>
                        </li>                        
                    </ul>
                </div>
            </div>
        </nav>
        <br/> <br />
        </>         
    );
}

export default LoginHeader;
