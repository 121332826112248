import React, { useEffect, useState } from "react";
import {Link} from 'react-router-dom';

const DocProfile = () =>{

    const [doctor, updateDoctor] = useState([]);

    const getDocProfile = () =>{
        let input={ "doctorid":localStorage.getItem("id"),
        "hospitalid": localStorage.getItem("hospitalid")          
    };
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(input)
        };
        fetch('https://www.medicalplanet.in/webapi/Doctor/viewprofile', requestOptions)
        .then(response => response.json())
        .then(data =>{
            // console.log(data);
            updateDoctor(data);
        });
    }

    useEffect(()=>{
        getDocProfile();
    },[1]);

    return(
        <div className="container">
            <div className="row mt-2">
                <div className="col-lg-12">
                    <h1 className="text-center text-info">Dr. {localStorage.getItem("name")} Profile</h1>
                </div>
            </div>
            <div className="row">         
                <div className="col-lg-2"></div>      
                <div className="col-lg-4 ">
                    <fieldset className="border p-2">
                        <legend  className="float-none w-auto">Doctor Details</legend>
                        <table className="d-flex align-center justify-content-center" cellPadding="8">
                            <tbody >
                                <tr>
                                    <th className="text-end">ID :</th>
                                    <td>{doctor.doctorid}</td>
                                </tr>
                                <tr>
                                    <th className="text-end">Name :</th>
                                    <td>{doctor.name}</td>
                                </tr>
                                <tr>
                                    <th className="text-end">Education :</th>
                                    <td>{doctor.education}</td>
                                </tr>
                                <tr>
                                    <th className="text-end">Speciality :</th>
                                    <td>{doctor.speciality}</td>
                                </tr>
                                <tr>
                                    <th className="text-end">Experience:</th>
                                    <td>{doctor.experience}</td>
                                </tr>
                                <tr>
                                    <th className="text-end">Profile :</th>
                                    <td>{doctor.profile}</td>
                                </tr>
                                <tr>
                                    <th className="text-end">Rating :</th>
                                    <td>{doctor.rating}</td>
                                </tr>
                                <tr>
                                    <th className="text-end">Amount :</th>
                                    <td>{doctor.amount}</td>
                                </tr>                                
                            </tbody>
                        </table>                        
                    </fieldset>
                </div>

                <div className="col-lg-4">
                    <fieldset className="border p-2">
                        <legend  className="float-none w-auto">Contact Details</legend>
                        <table className="d-flex align-center justify-content-center" cellPadding="8">
                            <tbody >
                                <tr>
                                    <th className="text-end">Mobile :</th>
                                    <td>{doctor.mobile}</td>
                                </tr>
                                <tr>
                                    <th className="text-end">Email :</th>
                                    <td>{doctor.email}</td>
                                </tr>
                                <tr>
                                    <th className="text-end">Address :</th>
                                    <td>{doctor.address}</td>
                                </tr>
                                <tr>
                                    <th className="text-end">Areaname :</th>
                                    <td>{doctor.areaname}</td>
                                </tr>
                                <tr>
                                    <th className="text-end">City :</th>
                                    <td>{doctor.city}</td>
                                </tr>
                                <tr>
                                    <th className="text-end">State :</th>
                                    <td>{doctor.state}</td>
                                </tr>
                                <tr>
                                    <th className="text-end">Pincode :</th>
                                    <td>{doctor.pincode}</td>
                                </tr>
                                <tr>
                                    <th className="text-end">Active :</th>
                                    <td>{doctor.active}</td>
                                </tr>                     
                            </tbody>
                        </table>     
                    </fieldset>
                </div>
                <div className="col-lg-2"></div>
            </div>
            <div className="row">
                <div className="col-lg-6 offset-3 text-center">
                    <Link to="/docProfileEdit"><button className="btn btn-primary"> <i className="fa fa-edit"></i> Edit</button></Link>
                </div>                
            </div>
        </div>
    )
}

export default DocProfile;