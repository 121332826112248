import React from "react";
import { HashRouter, Routes, Route } from "react-router-dom";
import LoginHeader from "./loginheader";
import Login from './login.js';

const LoginApp = () =>{
    return(
        <HashRouter>            
            <LoginHeader />
            <Login />
            <Routes>
                <Route exact path="/" element={<LoginHeader />} />
            </Routes>
        </HashRouter>
        
    );
}

export default LoginApp;