import PharmaBill from './pharmabill';
import ConsultationBilling from './consultationbilling';
import {
    Routes,
    Route,
    Link
  } from "react-router-dom";
const HospitalBilling = () =>{
    return(
            <div className="container mt-4">
                <div className="row">
                    <div className="col-lg-12 text-end">
                        <div className="btn-group">
                            <Link className="btn btn-success" to="/hospitalbilling/consulation">
                            <i className="fa fa-user-md"></i> Consultation Billing
                            </Link>
                            <Link className="btn btn-primary"  to="/hospitalbilling/pharma">
                            <i className="fa fa-medkit"></i> Pharma Billing
                            </Link>
                        </div>
                    </div>
                </div>
                <Routes>
                    <Route exact path="/hospitalbilling/consulation" element={<ConsultationBilling/>}/>
                    <Route exact path="/hospitalbilling/pharma" element={<PharmaBill/>}/>
                </Routes>
            </div>
    );
}

export default HospitalBilling;

