import React, { useEffect, useState } from "react";

const HospitalDoctor = () =>{
    const [docList, setDocList] = useState([]);
    const [message, updateMessage] = useState("");

    const getDoctors = () =>{
        let input={ "hospitalid":localStorage.getItem("hospitalid"),
            "id": localStorage.getItem("id")
        };
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(input)
        };
        fetch('https://www.medicalplanet.in/webapi/Hospital/doctorforhospital', requestOptions)
        .then(response => response.json())
        .then(data =>{
            if(data.length>0){
                // console.log(data);
                setDocList(data.reverse()); 
            }           
        });
    }

    // const deleteDocFromHospital = (doctorid, dname) =>{
    //     let input = {
    //         "hospitalid": localStorage.getItem("hospitalid"),
    //         "id": localStorage.getItem("id"),
    //         "doctorid":doctorid
    //     };
    //     const requestOptions = {
    //         method: 'POST',
    //         headers: { 'Content-Type': 'application/json' },
    //         body: JSON.stringify(input)
    //     };
    //     fetch("https://www.medicalplanet.in/webapi/Hospital/deletedoctorhospital", requestOptions)
    //         .then(response => response.text())
    //         .then(data => {
    //             updateMessage(dname + " Removed Successfully");
    //             setTimeout(()=>{
    //                 updateMessage("");
    //             },2000);                
    //             setDocList([]); 
    //             getDoctors();
    //         })
    // }

    useEffect(()=>{
        getDoctors();
    }, []);

    return(
        <div className="container">
            <p className="mt-4 text-center text-danger">{message}</p>
            {
                docList.map((ddata, index)=>{
                    return(
                        <div className="row" key={index}>
                            <h4 className="text-primary text-center">{index + 1} - Doctor List</h4>
                            <div className="col-lg-3">
                                <p>{ddata.name} - Rs. ({ddata.amount})</p>
                                <p>{ddata.education}</p>
                                <p>{ddata.speciality}</p>
                                <p>Exp: {ddata.experience} Years</p>
                                <p>{ddata.mobile}</p>
                                <p>{ddata.email}</p>
                            </div>
                            <div className="col-lg-3">
                                <p>{ddata.areaname}</p>
                                <p>{ddata.address}</p>
                                <p>{ddata.city}</p>
                                <p>{ddata.state}</p>
                                <p>{ddata.pincode}</p>
                            </div>
                            <div className="col-lg-6">
                                <p>Active: {ddata.active}</p>
                                {/* <p className="text-danger text-decoration-underline" onClick={deleteDocFromHospital.bind(this, ddata.doctorid, ddata.name)}>Remove From Hospital </p> */}
                                <p className="textjustify">{ddata.profile}</p>
                            </div>
                            <hr/>
                    </div>                    
                    )
                })
            }            
        </div>
    );
}

export default HospitalDoctor;