import React from "react";
import { HashRouter, Routes, Route } from "react-router-dom";
import HospitalHeader from "./hospitalheader";
import HospitalDashboard from './hospitaldashboard.js';
import HospitalDoctor from "./hospitaldoctor";
import HospitalStaff from "./hospitalstaff";
import HospitalLabs from './hospitallabs.js';
import HospitalPharma from './hospitalpharma.js';
import HospitalPatients from "./hospitalpatients";
import HospitalBilling from './hospitalbilling.js';
import CartDetails from "./cartdetails";

import AddNewMedicine from "./addnewmedicine";

import EditAppointment from '../Staff/editAppointment.js';

const HospitalApp = () =>{
    return(
        <HashRouter>
            <HospitalHeader />            
            <Routes>
                <Route exact path="/" element={<HospitalDashboard />} />
                <Route exact path="/hospitaldoctor" element={<HospitalDoctor />} />
                <Route exact path="/hospitalstaff" element={<HospitalStaff />} />
                <Route exact path="/hospitallabs" element={<HospitalLabs />} />
                <Route exact path="/hospitalpharma" element={<HospitalPharma />} />
                <Route exact path="/hospitalpatients" element={<HospitalPatients />} />
                <Route exact path="/hospitalbilling" element={<HospitalBilling />} />
                <Route exact path="/editappointment/:pid" element={<EditAppointment />} />

                <Route exact path="/addnewmedicine" element={<AddNewMedicine />} />
                <Route exact path="/cartdetails" element={<CartDetails />} />
            </Routes>
        </HashRouter>
    )
}

export default HospitalApp;